import { SVGProps, useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import { Button, ButtonVariant } from '../../../_core/button/button.component';
import { ITemplateBaseDetails } from '../../../../lib/types';
import { showError } from '../../../../lib/utils';
import { useDeleteTemplate, useRestoreTemplate } from '../../../domains/template/template.service';
import { IPersonalCompanyProps } from '../../../_core/core.types';
import { ArrowPathIcon, DocumentDuplicateIcon, EyeIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/20/solid';
import { IOnClickProps } from '../../../_core/click.types';

interface IButtonListOption extends IOnClickProps {
  Element: React.ComponentType<SVGProps<SVGSVGElement>>;
  text: string;
  tooltip?: string;
  variant?: ButtonVariant;
}

export interface ITemplateActionProps {
  index: number;
  isCompanyAdmin?: boolean;
  isGlobalAdmin?: boolean;
  onCreateRequest?: () => void;
  onEdit: () => void;
  onPreview: () => void;
  template: ITemplateBaseDetails;
}

export interface ITemplateActionButtonProps extends ITemplateActionProps, IPersonalCompanyProps {}

export const TemplateActionButton: React.FC<ITemplateActionButtonProps> = ({
  index,
  isCompanyAdmin,
  isGlobalAdmin,
  onCreateRequest,
  onEdit,
  onPreview,
  template
}) => {
  const { deleteTemplate, loading: deletingTemplate } = useDeleteTemplate();
  const { restoreTemplate, loading: restoringTemplate } = useRestoreTemplate();

  const previewTemplateAction: IButtonListOption = useMemo(
    () => ({
      Element: EyeIcon as React.ComponentType,
      onClick: onPreview,
      text: 'Preview',
      variant: 'secondary'
    }),
    [onPreview]
  );

  const createRequestFromTemplateAction: IButtonListOption = useMemo(
    () => ({
      Element: DocumentDuplicateIcon as React.ComponentType,
      onClick: onCreateRequest,
      text: 'Create Request',
      variant: 'secondary'
    }),
    [onCreateRequest]
  );

  const editTemplateAction: IButtonListOption = useMemo(
    () => ({ Element: PencilSquareIcon as React.ComponentType, onClick: onEdit, text: 'Edit', variant: 'secondary' }),
    [onEdit]
  );

  const onRestoreTemplate = useCallback(async () => {
    if (template.deletedAt)
      try {
        await restoreTemplate(template);
        toast.success(`Restored Template`);
      } catch (err) {
        showError('Unable to restore template', err as Error);
      }
  }, [template, restoreTemplate]);

  const restoreTemplateAction: IButtonListOption = useMemo(
    () => ({ Element: ArrowPathIcon as React.ComponentType, onClick: onRestoreTemplate, text: 'Restore' }),
    [onRestoreTemplate]
  );

  const onDeleteTemplate = useCallback(async () => {
    try {
      await deleteTemplate(template);
      toast.success(`Deleted Template`);
    } catch (err) {
      showError('Failed to delete template', err as Error);
    }
  }, [deleteTemplate, template]);

  const deleteTemplateAction: IButtonListOption = useMemo(
    () => ({ Element: TrashIcon as React.ComponentType, onClick: onDeleteTemplate, text: 'Delete' }),
    [onDeleteTemplate]
  );

  const options = useMemo(() => {
    const newOptions: IButtonListOption[] = [previewTemplateAction];
    if (onCreateRequest) newOptions.push(createRequestFromTemplateAction);

    if ((template.company && isCompanyAdmin) || isGlobalAdmin)
      newOptions.push(editTemplateAction, template.deletedAt ? restoreTemplateAction : deleteTemplateAction);

    return newOptions;
  }, [
    previewTemplateAction,
    onCreateRequest,
    createRequestFromTemplateAction,
    template.company,
    template.deletedAt,
    isCompanyAdmin,
    isGlobalAdmin,
    editTemplateAction,
    restoreTemplateAction,
    deleteTemplateAction
  ]);

  return (
    <div className="flex">
      {options.map((o) => (
        <Button
          key={`button-${index}-${o.text}`}
          icon={<o.Element height={20} width={20} />}
          loading={deletingTemplate || restoringTemplate}
          onClick={(e) => {
            if (o.onClick) {
              o.onClick(e);
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          size="fit"
          tooltip={o.text}
          variant={o.variant}
        />
      ))}
    </div>
  );
};
