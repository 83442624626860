import { IEntity } from '../../../../lib/types';
import { logError } from '../../../../lib/utils';
import { IPersonalCompanyProps } from '../../../_core/core.types';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { Loader } from '../../../_core/loader.component';
import { useSendClientInvite } from '../../client-invite/client-invite.service';
import { useEntities } from '../../entities/entity.service';
import { ISendClientInvitesProps } from '../invite.types';
import { SendClientInvitesForm } from './send-client-invites-form.component';

export interface ISendClientInvitesContainerProps extends IPersonalCompanyProps {
  isClientInvite?: boolean;
  entity?: IEntity;
}

interface ISendClientInvitesNestedContainerProps extends IFormWithStepperProps, ISendClientInvitesContainerProps {}

export const SendClientInvitesNestedContainer = ({
  onError,
  onSuccess,
  ...rest
}: ISendClientInvitesNestedContainerProps) => {
  const { entities, loading: loadingEntities, called: calledEntities } = useEntities();
  const { sendClientInvite } = useSendClientInvite();

  const handleSubmit = async ({ invites }: ISendClientInvitesProps) => {
    try {
      await resetFormMessages({ setSubmitError: onError, setSuccess: onSuccess });

      await Promise.all(
        invites.map(({ email, name, entity }) =>
          entity?.length
            ? Promise.all(entity.map(({ value: entityId }) => sendClientInvite({ email, name, entityId })))
            : sendClientInvite({ email, name })
        )
      );
      onSuccess('Successfully added contacts');
    } catch (err) {
      const errMsg = 'Unable to add contacts.';
      logError(errMsg, (err as Error).message);
      onError(errMsg);
    }

    return;
  };

  if (loadingEntities || !calledEntities) return <Loader />;

  return <SendClientInvitesForm entities={entities ?? []} onSubmit={handleSubmit} {...rest} />;
};
