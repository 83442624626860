import { Form, Formik, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { FormInfo } from '../../../_core/form/form-info.component';
import { IEditRequestTypesProps } from '../company.types';
import { IRequestType } from '../../../../lib/types';
import { FormActionsRow } from '../../../_core/form/input/form-actions-row';
import { OrderedOwnershipLevels, Ownership } from '../../../../lib/global.types';

interface IEditRequestTypesFormProps extends IRIFormProps<IEditRequestTypesProps> {
  requestTypes: IRequestType[];
  selectedRequestTypeIds: string[];
}

export const EditRequestTypesForm = ({
  requestTypes,
  selectedRequestTypeIds,
  onBack,
  onSkip,
  onSubmit
}: IEditRequestTypesFormProps) => {
  return (
    <Formik
      initialValues={
        {
          requestTypes: requestTypes
            .filter(({ _id }) => selectedRequestTypeIds.includes(_id))
            .map(({ _id, type }) => ({ label: type, value: _id }))
        } as IEditRequestTypesProps
      }
      onSubmit={onSubmit}
    >
      {(props: FormikProps<IEditRequestTypesProps>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-6 mt-10">
          <FormInfo
            title="Request types:"
            messages={[
              'We have included a default list of request types that you may have.',
              'These let you organize the types of requests that you make.',
              'You can make changes below or change them later in your settings.'
            ]}
          />
          <FormInputWithLabel
            id="requestTypes"
            name="requestTypes"
            label="Request Types"
            value={props.values.requestTypes}
            type="multiselect"
            placeholder="Select or Add Request Types"
            multiSelectProps={{
              canAdd: true,
              options: requestTypes.map(({ _id, company, type }) => ({
                label: type,
                value: _id,
                group: company ? Ownership.ORGANIZATION : Ownership.GLOBAL
              })),
              groupOrder: OrderedOwnershipLevels
            }}
          />
          <FormActionsRow>
            {!!onBack && <FormButton onPress={onBack} text="Back" type="button" variant="secondary" />}
            {!!onSkip && <FormButton onPress={onSkip} text="Skip" type="button" variant="secondary" />}
            <FormButton loading={props.isSubmitting} text={onSkip ? 'Next' : 'Update'} />
          </FormActionsRow>
        </Form>
      )}
    </Formik>
  );
};
