export const inputClassesLineHeightStr = 'py-1 sm:text-xs sm:leading-6';

const textClassesStr = `${inputClassesLineHeightStr} text-gray-900`;
export const inputClassesStr = `${textClassesStr} ring-inset focus:ring-2 focus:ring-inset focus:ring-indigo-600 block rounded-md border-0 shadow-sm ring-1 disabled:text-gray-400 disabled:ring-gray-300 disabled:bg-gray-100 disabled:placeholder:text-transparent`;

export const textClasses = textClassesStr.split(' ');
export const inputClasses = inputClassesStr.split(' ');

export const inputErrorClasses = ['ring-red-300', 'placeholder:text-red-400'];

export enum BlockType {
  Branch = 'branch',
  Checkbox = 'checkbox',
  DocProvide = 'docProvide',
  DocUpload = 'upload',
  Header = 'header',
  Question = 'question',
  QuestionGroup = 'questionGroup',
  Radio = 'radio',
  RepeatSection = 'repeatSection',
  Section = 'section',
  Text = 'paragraph',
  YesNo = 'yesNo'
}

export const BlockTitles: Record<BlockType, string> = {
  [BlockType.Branch]: 'Branch - Yes/No',
  [BlockType.Checkbox]: 'Checkbox',
  [BlockType.DocProvide]: 'Doc - Provide',
  [BlockType.DocUpload]: 'Doc - Upload',
  [BlockType.Header]: 'Header',
  [BlockType.Question]: 'Question',
  [BlockType.QuestionGroup]: 'Question Group',
  [BlockType.Radio]: 'Radio',
  [BlockType.RepeatSection]: 'Question Group Multi',
  [BlockType.Section]: 'Section',
  [BlockType.Text]: 'Text',
  [BlockType.YesNo]: 'Yes/No'
};
