import { IInvite, INVITE_STATUS } from '../../../lib/types';
import { IMultiSelectOption } from '../../_core/input/multiselect-input.component';

export interface ISendInvitesProps {
  department: IMultiSelectOption[];
  emails: string[];
  location: IMultiSelectOption[];
  roles?: IMultiSelectOption[];
}

export interface ISendClientInvite {
  email: string;
  name?: string;
  entity?: IMultiSelectOption[];
}

export interface ISendClientInvitesProps {
  invites: ISendClientInvite[];
}

export interface ICompanyInviteResult {
  invites: IInvite[];
}

export interface ICompanyInviteQueryParams {
  status?: string;
}

export interface IInviteResult {
  invite: IInvite;
}

export interface IInviteQueryParams {
  code: string;
  status?: INVITE_STATUS;
}

export enum FetchInviteErrors {
  COMPANY_DELETED = 'Organization has been deleted.',
  DOES_NOT_EXIST = 'Invite does not exist.',
  EXPIRED = 'Invite has expired.'
}
