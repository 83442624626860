import { useCallback, useState } from 'react';
import { Button } from '../../_core/button/button.component';
import { ISectionData } from './section.types';
import { useConfirm } from '../../_core/confirm/confirm.utils';
import toast from 'react-hot-toast';
import { IBranchData } from '../branch';
import { IRepeatSectionData } from '../repeat-section';

interface ISectionButtonsProps {
  canComplete: boolean;
  completed: boolean;
  getData: () => Promise<ISectionData | null>;
  prevBlock: number | null;
  nextBlock: number | null;
  navigateToBlock: (_: number) => void;
  toggleCompleted: (_: boolean) => void;
  uid: string;
}

export const SectionButtons = ({
  canComplete,
  getData,
  prevBlock,
  nextBlock,
  navigateToBlock,
  toggleCompleted,
  uid,
  ...rest
}: ISectionButtonsProps) => {
  const [completed, setCompleted] = useState(rest.completed);
  const [completing, setCompleting] = useState(false);

  const { ConfirmationDialog, confirm } = useConfirm('Are you sure everything is complete?');

  const countUncompletedSectionFields = useCallback((data: ISectionData) => {
    let uncompletedOptional = 0;
    let uncompletedRequired = 0;

    for (const block of data.outputData.blocks) {
      const isBlockUnfilled = 'filled' in block.data && !block.data.filled;
      const isBlockRequired = 'required' in block.data && block.data.required;

      // Determine filled status of nested section blocks
      if (block.type === 'questionGroup') {
        const uncompletedQuestionGroup = countUncompletedSectionFields(block.data);
        uncompletedOptional += uncompletedQuestionGroup.uncompletedOptional;
        uncompletedRequired += uncompletedQuestionGroup.uncompletedRequired;
      }
      // Branch and Multi Question Group should be considered as 1 item if triggering questions are not answered, and otherwise count their nested section data
      else if (block.type === 'branch') {
        if (isBlockUnfilled) {
          if (isBlockRequired) uncompletedRequired += 1;
          else uncompletedOptional += 1;
        } else {
          const branchData = block.data as IBranchData;
          if (branchData.questionData.value) {
            const branchSelectedSection =
              branchData.sectionsData[
                branchData.questionData.options.findIndex((o) => o === branchData.questionData.value)
              ];

            const uncompletedBranchSection = countUncompletedSectionFields(branchSelectedSection);
            uncompletedOptional += uncompletedBranchSection.uncompletedOptional;
            uncompletedRequired += uncompletedBranchSection.uncompletedRequired;
          } else toast.error('Failed to determine completion status of branch block.');
        }
      } else if (block.type === 'repeatSection') {
        if (isBlockUnfilled) {
          if (isBlockRequired) uncompletedRequired += 1;
          else uncompletedOptional += 1;
        } else {
          const repeatSectionData = block.data as IRepeatSectionData;
          if (repeatSectionData.repeatCount) {
            for (let i = 0; i < repeatSectionData.repeatCount; i++) {
              const sectionData = repeatSectionData.sectionsData[i];
              const uncompletedRepeatSection = countUncompletedSectionFields(sectionData);
              uncompletedOptional += uncompletedRepeatSection.uncompletedOptional;
              uncompletedRequired += uncompletedRepeatSection.uncompletedRequired;
            }
          } else toast.error('Failed to determine completion status of Question Group - Multi block.');
        }
      } else {
        if (isBlockUnfilled) {
          if (isBlockRequired) uncompletedRequired += 1;
          else uncompletedOptional += 1;
        }
      }
    }

    return { uncompletedOptional, uncompletedRequired };
  }, []);

  const onToggleComplete = useCallback(() => {
    setCompleting(true);
    getData()
      .then((latestData) => {
        if (!latestData) return null;

        if (completed) {
          toggleCompleted(false);
          setCompleted(false);
        } else {
          const { uncompletedOptional, uncompletedRequired } = countUncompletedSectionFields(latestData);

          if (uncompletedRequired) {
            toast.error(
              `Cannot complete section. You have ${uncompletedRequired} missing answers to required questions`,
              { id: 'incomplete-required-' + uid }
            );
            return;
          }

          if (uncompletedOptional)
            confirm(`You have ${uncompletedOptional} empty fields.`).then((confirmed) => {
              if (confirmed) {
                toggleCompleted(true);
                setCompleted(true);
                if (nextBlock) navigateToBlock(nextBlock);
              }
            });
          else {
            toggleCompleted(true);
            setCompleted(true);
            if (nextBlock) navigateToBlock(nextBlock);
          }
        }
      })
      .finally(() => {
        setCompleting(false);
      });
  }, [completed, confirm, countUncompletedSectionFields, getData, navigateToBlock, nextBlock, toggleCompleted, uid]);

  return (
    <>
      <div className="w-1/2 flex justify-around mx-auto py-2">
        {prevBlock !== null ? (
          <Button text="Prev" variant="secondary" size="medium" onClick={() => navigateToBlock(prevBlock)} />
        ) : (
          <div className="w-28" />
        )}
        {canComplete ? (
          <Button
            text={completed ? 'Re-open Section' : 'Mark Section Completed'}
            size="xl"
            onClick={onToggleComplete}
            loading={completing}
          />
        ) : (
          <div className="w-52" />
        )}
        {nextBlock !== null ? (
          <Button text="Next" variant="secondary" size="medium" onClick={() => navigateToBlock(nextBlock)} />
        ) : (
          <div className="w-28" />
        )}
      </div>
      <ConfirmationDialog />
    </>
  );
};
