import { BlockTool, BlockToolConstructorOptions, ToolConfig } from '@editorjs/editorjs/types/tools';
import { createRoot } from 'react-dom/client';
import { SelectTemplateInputContainer } from './select-template-input';
import { API } from '@editorjs/editorjs';
import { renderWithBlockFocusWrapper } from '../_core/utils/editor.utils';
import { TEMPLATE_TYPE } from '../../../lib/types';

export interface ISelectTemplateConfig {
  templateType: TEMPLATE_TYPE;
}

export class SelectTemplate implements BlockTool {
  private api: API;
  private config: ToolConfig<ISelectTemplateConfig>;

  constructor(props: BlockToolConstructorOptions<object, ISelectTemplateConfig>) {
    this.api = props.api;
    this.config = props.config ?? { templateType: TEMPLATE_TYPE.BLOCK };
  }

  static get toolbox() {
    return {
      title: 'From Template',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z" /></svg>'
    };
  }

  static get isReadOnlySupported(): boolean {
    return true;
  }

  static get conversionConfig() {
    return { export: () => JSON.stringify({}), import: () => {} };
  }

  render() {
    const wrapper = document.createElement('div');

    // Render upload input
    wrapper.classList.add('flex', 'items-center', 'w-full', 'pb-2', 'gap-4');
    const root = createRoot(wrapper);
    root.render(
      <SelectTemplateInputContainer
        onSelectTemplate={(t) => {
          if (t.blocks.length) {
            const selectTemplateBlockIndex = this.api.blocks.getCurrentBlockIndex(); // Get selected block index of curr block before new block is inserted and steals focus
            this.api.blocks.insert(t.blocks[0].type, t.blocks[0].data);
            this.api.blocks.delete(selectTemplateBlockIndex);
          }
        }}
        type={this.config.templateType}
      />
    );

    return renderWithBlockFocusWrapper(wrapper);
  }

  save() {}

  // Don't ever allow saving the template select block into the form, we want it to live temporarily in form editing state
  validate() {
    return false;
  }
}
