import { PropsWithChildren } from 'react';
import { useRouteError } from 'react-router-dom';

interface IReactRouterError {
  statusText?: string;
  message?: string;
}

export default function ErrorPage({ children }: PropsWithChildren) {
  const error = useRouteError() as IReactRouterError;
  if (error?.message) console.error(error);

  return (
    <div id="error-page">
      <div id="error-page" className="flex min-h-full flex-1 flex-col justify-center">
        <div className="mx-auto max-w-[90%] w-full p-4 flex flex-1 flex-col m-6 bg-white rounded-2xl">
          <h1 className="text-red-500">Oops!</h1>
          <h3 className="text-red-500">Sorry, an unexpected error has occurred.</h3>
          <h3 className="text-red-500">{children}</h3>
        </div>
      </div>
    </div>
  );
}
