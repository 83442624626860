import { Button } from '../../../_core/button/button.component';
import { IClientInvite, IClient, IEntity, ACTIVE_STATUS, INVITE_STATUS } from '../../../../lib/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ITableRowValues } from '../../../_core/table/table.component';
import { clientActiveStatus, inviteStatus } from '../../../../utils/wording.helpers';
import { ContactsTable } from './contacts-table.component';
import { SelectedContactsModels } from './contacts-table.types';
import { ContactsTableMultiselectActionButton } from './contacts-table-multiselect-action-button/contacts-table-multiselect-action-button';
import { useCompanyLocations } from '../../locations/location.service';
import { useCompanyDepartments } from '../../departments/department.service';
import { IContactsActionButtonProps, ContactsActionButton } from './contacts-action-button';
import { RIDialog } from '../../../_core/dialog/dialog.component';
import { EditDepartmentsContainer } from '../../departments/edit/edit-departments-form.container';
import { EditLocationsContainer } from '../../locations/edit/edit-locations-form.container';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../../_routes';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid';
import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';
import { SendClientInvitesContainer } from '../../invite/send-client/send-client-invites-form.container';
import { useTableFilterConfig } from '../../../_core/table/table-utils';
import { TableFilterKeys } from '../../../_core/table/table.types';

interface IContactsTableRowValues extends ITableRowValues {
  invite?: IClientInvite;
  client?: IClient;
}

interface IContactsTableNestedContainerProps extends IAdminProps, IPersonalCompanyProps {
  invites: IClientInvite[];
  clients: IClient[];
  entity?: IEntity;
}

export const ContactsTableNestedContainer: React.FC<IContactsTableNestedContainerProps> = ({
  clients,
  invites,
  isAdmin,
  isPersonalCompany,
  entity
}) => {
  const navigate = useNavigate();

  const [inviteContacts, setInviteContacts] = useState(false);
  const [selected, setSelected] = useState<boolean[] | null>(null);
  const [showEditDepartmentsModal, setShowEditDepartmentsModal] = useState<IClientInvite | IClient | null>(null);
  const [showEditLocationsModal, setShowEditLocationsModal] = useState<IClientInvite | IClient | null>(null);

  const { departments } = useCompanyDepartments();
  const { locations } = useCompanyLocations();

  const onSelect = (index: number, isSelected?: boolean) =>
    setSelected((prev) => {
      if (!prev) return null;
      const newSelected = [...prev];
      newSelected[index] = isSelected !== undefined ? isSelected : !newSelected[index];
      return newSelected;
    });

  const showEntity = !entity;

  const [filterConfig, filterFunc] = useTableFilterConfig({
    filters: [
      {
        title: TableFilterKeys.STATUS,
        placeholder: 'Filter by status',
        options: [ACTIVE_STATUS.ACTIVE, INVITE_STATUS.PENDING, 'EXPIRED', ACTIVE_STATUS.INACTIVE],
        value: [ACTIVE_STATUS.ACTIVE],
        matcher: (curr: IClient | IClientInvite, filterValue: string[]) => {
          if (!filterValue.length) return true;
          if (curr.deletedAt || ('user' in curr && (curr.entityDeletedAt || curr.user.deletedAt)))
            return filterValue.includes(ACTIVE_STATUS.INACTIVE);
          else if ('user' in curr) return filterValue.includes(ACTIVE_STATUS.ACTIVE);
          else if (curr.isExpired) return filterValue.includes('EXPIRED');
          return filterValue.includes(INVITE_STATUS.PENDING);
        }
      }
    ]
  });

  const contacts = useMemo(() => {
    if (!clients || !invites) return null;

    const actionButtonProps: Omit<IContactsActionButtonProps, 'invite' | 'client'> = {
      departments,
      entityId: entity?._id ?? '',
      locations,
      setShowEditDepartmentsModal,
      setShowEditLocationsModal
    };

    const newContacts: IContactsTableRowValues[] = [];
    clients?.filter(filterFunc)?.forEach((client) => {
      const newContactsValues: IContactsTableRowValues = {
        client,
        values: [
          { children: client.name ?? client.user.name },
          { children: client.user.email },
          { children: clientActiveStatus(client) }
        ]
      };

      if (!isPersonalCompany)
        newContactsValues.values.push(
          {
            children: client.department
              .map((d) => d.name)
              .sort()
              .join(', ')
          },
          {
            children: client.location
              .map((d) => d.name)
              .sort()
              .join(', ')
          }
        );

      // TODO: Show entity for entity member
      if (showEntity) newContactsValues.values.push({ children: '' });

      if (isAdmin)
        newContactsValues.values.push({
          children: (
            <ContactsActionButton {...actionButtonProps} isPersonalCompany={isPersonalCompany} client={client} />
          )
        });

      newContactsValues.values.push({
        children: (
          <Button
            onClick={() => navigate(ROUTE_PATHS.CLIENT_PROFILE + '/' + client._id)}
            icon={<ArrowTopRightOnSquareIcon className="w-6 h-6" />}
          />
        )
      });

      newContacts.push(newContactsValues);
    });

    invites?.forEach((invite) => {
      const newContactsValues: IContactsTableRowValues = {
        invite,
        values: [{ children: null }, { children: invite.email }, { children: inviteStatus(invite) }]
      };

      if (!isPersonalCompany)
        newContactsValues.values.push(
          {
            children: invite.department
              .map((d) => d.name)
              .sort()
              .join(', ')
          },
          {
            children: invite.location
              .map((d) => d.name)
              .sort()
              .join(', ')
          }
        );

      if (showEntity) newContactsValues.values.push({ children: invite.entity?.name ?? '' });

      if (isAdmin)
        newContactsValues.values.push({
          children: (
            <ContactsActionButton {...actionButtonProps} isPersonalCompany={isPersonalCompany} invite={invite} />
          )
        });

      // Don't show member profile open link for invites
      newContactsValues.values.push({ children: '' });

      newContacts.push(newContactsValues);
    });

    return newContacts;
  }, [
    clients,
    invites,
    departments,
    entity?._id,
    locations,
    filterFunc,
    isPersonalCompany,
    showEntity,
    isAdmin,
    navigate
  ]);

  // TODO: Want to test this with pagination
  useEffect(() => setSelected((s) => contacts?.map((_, i) => !!s && i < s.length && s[i]) ?? null), [contacts]);

  const getSelectedModels = useCallback(() => {
    if (selected && contacts) {
      const selectedInvites: IClientInvite[] = [];
      const selectedClients: IClient[] = [];
      selected.forEach((s, i) => {
        if (s && i < contacts.length) {
          const curr = contacts[i];
          if (curr.client) selectedClients.push(curr.client);
          else if (curr.invite) selectedInvites.push(curr.invite);
        }
      });

      return { selectedInvites, selectedClients } as SelectedContactsModels;
    }
  }, [selected, contacts]);

  if (!selected || !contacts) return null;

  return (
    <>
      <ContactsTable
        isAdmin={isAdmin}
        isPersonalCompany={isPersonalCompany}
        selected={selected}
        contacts={contacts}
        onSelect={onSelect}
        onSelectAll={(v) =>
          setSelected((s) => {
            if (s) return s.map(() => v);
            return null;
          })
        }
        showEntity={showEntity}
        filterConfig={filterConfig}
      >
        {isAdmin && (
          <>
            <Button onClick={() => setInviteContacts(true)} text="Add Contacts" size="large" />
            <ContactsTableMultiselectActionButton
              getSelectedModels={getSelectedModels}
              selected={selected}
              setSelected={setSelected}
            />
          </>
        )}
      </ContactsTable>
      <RIDialog open={inviteContacts} setOpen={setInviteContacts}>
        <SendClientInvitesContainer entity={entity} />
      </RIDialog>
      {!!departments && (
        <RIDialog open={!!showEditDepartmentsModal} setOpen={(o) => setShowEditDepartmentsModal((s) => (o ? s : null))}>
          <EditDepartmentsContainer
            departments={departments}
            initialDepartments={showEditDepartmentsModal?.department ?? []}
            targetType={showEditDepartmentsModal && 'code' in showEditDepartmentsModal ? 'client-invite' : 'client'}
            targetId={showEditDepartmentsModal?._id ?? ''}
          />
        </RIDialog>
      )}
      {!!locations && (
        <RIDialog open={!!showEditLocationsModal} setOpen={(o) => setShowEditLocationsModal((s) => (o ? s : null))}>
          <EditLocationsContainer
            locations={locations}
            initialLocations={showEditLocationsModal?.location ?? []}
            targetType={showEditLocationsModal && 'code' in showEditLocationsModal ? 'client-invite' : 'client'}
            targetId={showEditLocationsModal?._id ?? ''}
          />
        </RIDialog>
      )}
    </>
  );
};
