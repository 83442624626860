import { Button } from '../../../_core/button/button.component';
import { IEntity } from '../../../../lib/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { EntityTable, IEntityTableRowValues } from './entity-table.component';
import { useCompanyLocations } from '../../../domains/locations/location.service';
import { useCompanyDepartments } from '../../../domains/departments/department.service';
import { RIDialog } from '../../../_core/dialog/dialog.component';
import { EditDepartmentsContainer } from '../../../domains/departments/edit/edit-departments-form.container';
import { EditLocationsContainer } from '../../../domains/locations/edit/edit-locations-form.container';
import { CreateEntityStepper } from '../../../domains/entities/create/create-entity-stepper.component';
import { activeStatus } from '../../../../utils/wording.helpers';
import { EntityActionButton, IEntityActionButtonProps } from './entity-action-button';
import { EntityTableMultiselectActionButton } from './entity-table-multiselect-action-button/entity-table-multiselect-action-button';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../../../_routes';
import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';
import { IEntityTableClientModeProps } from './entity-table.types';
import { useTableFilterConfig } from '../../../_core/table/table-utils';

interface IEntityTableNestedContainerProps extends IAdminProps, IPersonalCompanyProps {
  clientModeConfig?: IEntityTableClientModeProps;
  entities?: IEntity[];
}

export const EntityTableNestedContainer: React.FC<IEntityTableNestedContainerProps> = ({
  clientModeConfig,
  entities,
  isAdmin,
  isPersonalCompany
}) => {
  const navigate = useNavigate();

  const [createEntity, setCreateEntity] = useState(false);
  const [selected, setSelected] = useState<boolean[] | null>(null);
  const [showEditDepartmentsModal, setShowEditDepartmentsModal] = useState<IEntity | null>(null);
  const [showEditLocationsModal, setShowEditLocationsModal] = useState<IEntity | null>(null);

  const { departments } = useCompanyDepartments();
  const { locations } = useCompanyLocations();

  const onSelect = (index: number, isSelected?: boolean) =>
    setSelected((prev) => {
      if (!prev) return null;
      const newSelected = [...prev];
      newSelected[index] = isSelected !== undefined ? isSelected : !newSelected[index];
      return newSelected;
    });

  // TODO: Want to test this with pagination
  useEffect(() => setSelected((s) => entities?.map((_, i) => !!s && i < s.length && s[i]) ?? null), [entities]);

  const getSelectedModels = useCallback(() => {
    if (selected && entities) {
      const selectedEntities: IEntity[] = [];
      selected.forEach((s, i) => {
        if (s && i < entities.length) selectedEntities.push(entities[i]);
      });

      return selectedEntities;
    }
  }, [selected, entities]);

  const [filterConfig, filterFunc] = useTableFilterConfig({ activeStatusFilter: true });

  const entityRows = useMemo(() => {
    const actionButtonProps: Omit<IEntityActionButtonProps, 'entity'> = {
      departments,
      locations,
      setShowEditDepartmentsModal,
      setShowEditLocationsModal
    };

    return entities?.filter(filterFunc)?.map((entity) => {
      const row: IEntityTableRowValues = {
        entity,
        values: [
          { children: entity.name },
          { children: activeStatus(!entity.deletedAt) },
          { children: entity.mainContact?.name }
        ]
      };

      if (!isPersonalCompany)
        row.values.push(
          {
            children: entity.department
              .map((d) => d.name)
              .sort()
              .join(', ')
          },
          {
            children: entity.location
              .map((d) => d.name)
              .sort()
              .join(', ')
          }
        );

      if (!clientModeConfig)
        row.values.push({
          children: <EntityActionButton {...actionButtonProps} entity={entity} isPersonalCompany={isPersonalCompany} />
        });

      row.values.push({
        children: (
          <Button
            onClick={() => navigate(ROUTE_PATHS.ENTITY_PROFILE + '/' + entity._id)}
            icon={<ArrowTopRightOnSquareIcon className="w-6 h-6" />}
          />
        )
      });

      return row;
    });
  }, [departments, locations, entities, filterFunc, isPersonalCompany, clientModeConfig, navigate]);

  if (!selected || !entityRows) return null;

  return (
    <>
      <EntityTable
        isAdmin={isAdmin}
        isPersonalCompany={isPersonalCompany}
        selected={selected}
        entities={entityRows}
        onSelect={onSelect}
        onSelectAll={(v) =>
          setSelected((s) => {
            if (s) return s.map(() => v);
            return null;
          })
        }
        filterConfig={filterConfig}
      >
        <>
          {isAdmin && !clientModeConfig && (
            <Button onClick={() => setCreateEntity(true)} text="Create Company" size="large" />
          )}
          <EntityTableMultiselectActionButton
            clientModeConfig={clientModeConfig}
            getSelectedModels={getSelectedModels}
            selected={selected}
            setSelected={setSelected}
          />
        </>
      </EntityTable>
      <RIDialog open={createEntity} setOpen={setCreateEntity}>
        <CreateEntityStepper isPersonalCompany={isPersonalCompany} close={() => setCreateEntity(false)} />
      </RIDialog>
      {!!departments && !!showEditDepartmentsModal && (
        <RIDialog open={!!showEditDepartmentsModal} setOpen={(o) => setShowEditDepartmentsModal((s) => (o ? s : null))}>
          <EditDepartmentsContainer
            departments={departments}
            targetId={showEditDepartmentsModal._id}
            targetType="entity"
            initialDepartments={showEditDepartmentsModal.department}
          />
        </RIDialog>
      )}
      {!!locations && !!showEditLocationsModal && (
        <RIDialog open={!!showEditLocationsModal} setOpen={(o) => setShowEditLocationsModal((s) => (o ? s : null))}>
          <EditLocationsContainer
            locations={locations}
            targetId={showEditLocationsModal._id}
            targetType="entity"
            initialLocations={showEditLocationsModal.location}
          />
        </RIDialog>
      )}
    </>
  );
};
