import { PropsWithChildren } from 'react';

interface ITooltipProps extends PropsWithChildren {
  direction?: 'left' | 'top';
  distance?: 'md' | 'sm';
  singleLine?: boolean;
  text?: string;
}

// NOTE: No need for a typography usecase here yet, because text is so basic and custom and could be controlled with tailwind font customizations
export const Tooltip = ({ children, direction = 'left', distance = 'md', singleLine, text }: ITooltipProps) => {
  if (!text) return children;

  const isMedium = distance === 'md';
  const additionalClasses = [
    direction === 'left' ? (isMedium ? 'right-10' : 'right-6') : isMedium ? 'bottom-10' : 'bottom-6',
    singleLine ? 'whitespace-nowrap' : ''
  ]
    .filter((c) => c)
    .join(' ');

  return (
    <span className="group relative flex items-center">
      {children}
      <span
        className={`absolute scale-0 rounded bg-gray-800 p-2 transition-all text-xs text-white group-hover:scale-100 ${additionalClasses}`}
      >
        {text}
      </span>
    </span>
  );
};
