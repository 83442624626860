import { Form, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { IClient, IEntity, IMember, IRequestType, TEMPLATE_TYPE } from '../../../../lib/types';
import { PropsWithChildren, useMemo } from 'react';
import { ICreateRequestProps } from '../request.types';
import { useEntityMembers } from '../../entity-members/entity-members.service';
import { IMultiSelectOption } from '../../../_core/input/multiselect-input.component';
import { useTemplates } from '../../template/template.service';
import { OrderedOwnershipLevels, Ownership } from '../../../../lib/global.types';

export interface IEditRequestFormFieldProps {
  clients: IClient[];
  entities: IEntity[];
  requestTypes: IRequestType[];
  staff: IMember[];
  hasBeenCreated?: boolean;
}

export const EditRequestFormFields = ({
  clients,
  entities,
  requestTypes,
  staff,
  createdById,
  hasBeenCreated,

  // Formik props
  children,
  values
}: IEditRequestFormFieldProps &
  FormikProps<ICreateRequestProps> &
  PropsWithChildren & {
    createdById: string;
  }) => {
  // Fetch entity members
  const { entityMembers, loading: loadingEntityMembers } = useEntityMembers({
    entityId: values.entity?.length ? values.entity[0]?.value : undefined
  });

  const activeEntityMembers = useMemo(() => entityMembers?.filter((em) => !em.deletedAt), [entityMembers]);

  // Fetch templates
  const { templates, loading: loadingTemplates } = useTemplates([TEMPLATE_TYPE.REQUEST]);

  const templateOptions = useMemo(
    () =>
      templates
        ?.filter((t) => t.company || !t.deletedAt)
        ?.map(
          (t) =>
            ({
              value: t._id,
              label: t.title,
              secondaryLabel: t.description,
              group: t.company ? Ownership.ORGANIZATION : Ownership.GLOBAL
            } as IMultiSelectOption)
        ),
    [templates]
  );

  const clientGroupOrder = useMemo(
    () =>
      values.entity?.length && values.entity?.[0] ? [values.entity[0]?.label + "'s Contacts", 'Other'] : undefined,
    [values.entity]
  );

  const clientOptions = useMemo(() => {
    return clients
      .filter((c) => !c.deletedAt && !c.user.deletedAt)
      .map(
        (d) =>
          ({
            label: d.name ?? d.user.name,
            secondaryLabel: d.user.email,
            value: d._id,
            group:
              values.entity?.length && activeEntityMembers?.find((em) => em.user._id === d.user._id)
                ? values.entity[0]?.label + "'s Contacts"
                : 'Other'
          } as IMultiSelectOption)
      );
  }, [activeEntityMembers, clients, values.entity]);

  const staticOptions = useMemo(
    () => ({
      entities: entities.filter((e) => !e.deletedAt).map((e) => ({ label: e.name, value: e._id })),
      requestTypes: requestTypes.filter((rt) => !rt.deletedAt).map((l) => ({ label: l.type, value: l._id })),
      staff: staff
        .filter((s) => !s.deletedAt && !s.user.deletedAt && s.user._id !== createdById)
        .map((d) => ({ label: d.user.name, secondaryLabel: d.user.email, value: d._id }))
    }),
    [createdById, entities, requestTypes, staff]
  );

  return (
    <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-6 mt-10">
      <FormInputWithLabel
        autoFocus
        id="name"
        name="name"
        label="Request Name"
        placeholder="Request Name"
        value={values.name}
      />
      <FormInputWithLabel
        id="assignedTo"
        name="assignedTo"
        label="Contact"
        placeholder="Primary contact"
        value={values.assignedTo}
        type="multiselect"
        multiSelectProps={{ options: clientOptions, singleSelect: true, nullable: true, groupOrder: clientGroupOrder }}
        disabled={values.entity && (!entityMembers || loadingEntityMembers)}
      />
      <FormInputWithLabel
        id="ccClients"
        name="ccClients"
        label="Contacts CC'd"
        value={values.ccClients}
        type="multiselect"
        placeholder="CC Contacts"
        multiSelectProps={{ options: clientOptions, nullable: true, groupOrder: clientGroupOrder }}
        disabled={values.entity && (!entityMembers || loadingEntityMembers)}
      />
      <FormInputWithLabel
        id="staff"
        name="staff"
        label="Team Members CC'd"
        value={values.staff}
        type="multiselect"
        placeholder="CC Team Member"
        multiSelectProps={{ options: staticOptions.staff, nullable: true }}
      />
      <FormInputWithLabel
        id="entity"
        name="entity"
        label="Company"
        value={values.entity}
        type="multiselect"
        placeholder="Assign to company"
        multiSelectProps={{ options: staticOptions.entities, singleSelect: true, nullable: true }}
      />
      <FormInputWithLabel
        id="requestType"
        name="requestType"
        label="Request Type"
        value={values.requestType}
        type="multiselect"
        placeholder="Select request type"
        multiSelectProps={{ options: staticOptions.requestTypes, singleSelect: true, nullable: true }}
      />
      <FormInputWithLabel
        id="deadline"
        name="deadline"
        label="Deadline"
        placeholder="Enter deadline date"
        value={values.deadline}
        type="date"
      />
      <FormInputWithLabel id="notes" name="notes" label="Notes" placeholder="Optional notes" value={values.notes} />
      {!hasBeenCreated && (
        <FormInputWithLabel
          id="templateId"
          name="templateId"
          label="Template"
          placeholder="Select Template"
          type="multiselect"
          value={values.templateId}
          multiSelectProps={{
            nullable: true,
            singleSelect: true,
            options: templateOptions,
            groupOrder: OrderedOwnershipLevels
          }}
          disabled={loadingTemplates || !templates}
        />
      )}
      {children}
    </Form>
  );
};
