import { IOnClickProps } from '../click.types';

export enum TableFilterKeys {
  OWNERSHIP = 'Ownership',
  STATUS = 'Status',
  TYPE = 'Type'
}

export interface ITableSelectableProps {
  selectable?: boolean; // TODO: Need to implement selected functionality
}

export interface ITableCellProps extends React.PropsWithChildren {
  header?: boolean;
  key?: string;
}

export interface ITableRowProps extends ITableSelectableProps, IOnClickProps {
  onSelect?: (_selected: boolean) => void;
  selected?: boolean;
  values: ITableCellProps[];
}
