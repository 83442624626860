import { FormLabel } from '../form-label.component';
import { FormInput, IFormInputProps } from './form-input.component';

interface IFormInputWithLabelProps extends IFormInputProps {
  autoFocus?: boolean;
  label: string;
}

export const FormInputWithLabel = ({ label, ...inputProps }: IFormInputWithLabelProps) => {
  let typeClasses = '';
  switch (inputProps.type) {
    case 'checkbox':
      typeClasses = 'items-center';
      break;
    default:
      typeClasses = 'items-start justify-between';
  }

  return (
    <div>
      <div className={`flex flex-row ${typeClasses}`}>
        {inputProps.type !== 'checkbox' && <FormLabel label={label} name={inputProps.name} />}
        <FormInput {...inputProps} />
        {inputProps.type === 'checkbox' && (
          <div className="ml-4">
            <FormLabel label={label} name={inputProps.name} />
          </div>
        )}
      </div>
    </div>
  );
};
