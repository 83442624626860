import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { FormLabel } from '../../../_core/form/form-label.component';
import { FormEditor } from '../../../_pages/FormBuilderPage/form-editor.component';
import EditorJS, { OutputData } from '@editorjs/editorjs';
import { useRef } from 'react';
import { useFetchCurrUser } from '../../users/user.service';
import { ITemplateBaseDetails } from '../../../../lib/types';

export interface ICreateTemplateFormValues {
  description?: string;
  isGlobal?: boolean;
  title: string;
}

export interface ICreateTemplateFormProps extends IRIFormProps<ICreateTemplateFormValues> {
  data: OutputData;
  template?: ITemplateBaseDetails;
}

export const CreateTemplateForm = ({ data, onSubmit, template }: ICreateTemplateFormProps) => {
  const { isGlobalAdmin } = useFetchCurrUser();

  const ref = useRef<EditorJS>();

  const validateForm = (values: ICreateTemplateFormValues) => {
    const errors: FormikErrors<ICreateTemplateFormValues> = {};

    if (!values.title.trim()) errors.title = 'Required';

    return errors;
  };

  return (
    <Formik
      initialValues={
        {
          title: template?.title ?? '',
          description: template?.description ?? '',
          isGlobal: template ? !template.company : false
        } as ICreateTemplateFormValues
      }
      onSubmit={(values) =>
        onSubmit({ ...values, description: values.description?.trim(), title: values.title.trim() })
      }
      validate={validateForm}
      validateOnBlur={false}
    >
      {(props: FormikProps<ICreateTemplateFormValues>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-6 mt-10">
          <FormInputWithLabel id="title" name="title" label="Title" value={props.values.title} />
          <FormInputWithLabel
            id="description"
            name="description"
            label="Description"
            value={props.values.description}
          />
          {!!isGlobalAdmin && (
            <FormInputWithLabel
              id="isGlobal"
              name="isGlobal"
              label="Globally Available"
              type="checkbox"
              value={props.values.isGlobal}
            />
          )}
          <div className="flex items-center justify-between w-full">
            <FormLabel label="Template" name="template" />
            <span className="text-xs">
              <b>Contact View Only</b> - Changes made in this window will have no effect on template
            </span>
          </div>
          <FormEditor
            data={data}
            editorblock={'create-template-editorjs'}
            editorRef={ref}
            disabled
            onSave={async () => undefined}
            readOnly
            requestId=""
          />
          <FormButton loading={props.isSubmitting} text={template ? 'Update' : 'Create'} />
        </Form>
      )}
    </Formik>
  );
};
