import { LowlightText } from '../typography';
import { ITableCellProps } from './table.types';

export const TableCell = ({ children, header }: ITableCellProps) => {
  const childrenIsString = typeof children === 'string';
  const childComponents = childrenIsString ? <LowlightText>{children}</LowlightText> : children;

  if (header)
    return (
      <th scope="col" className="px-6 py-3">
        {childComponents}
      </th>
    );

  return <td className="px-6 py-1">{childComponents}</td>;
};
