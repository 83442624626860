import { useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import { useUpdateLocation } from '../location.service';
import { UpdateLocationForm } from './update-location-form.component';
import { ILocation } from '../../../../lib/types';
import { logError } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';

export const UpdateLocationContainer = ({ _id, name }: ILocation) => {
  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const { updateLocation } = useUpdateLocation();

  const handleUpdate = async (props: { name: string }) => {
    try {
      await resetFormMessages({ setSubmitError, setSuccess });

      await updateLocation({ ...props, _id });
      setSubmitError(null);
      setSuccess('Successfully updated location!');
    } catch (err) {
      const errMsg = 'Unable to update location.';
      logError(errMsg, (err as Error).message);
      setSuccess(null);
      setSubmitError(errMsg);
    }
  };

  return (
    <FormContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title="Update Location"
    >
      <UpdateLocationForm name={name} onSubmit={handleUpdate} />
    </FormContainer>
  );
};
