import { Form, Formik, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { IEntity } from '../../../../lib/types';
import { useMemo } from 'react';
import { IMultiSelectOption } from '../../../_core/input/multiselect-input.component';

interface IUpdateClientFormValues {
  entities: IMultiSelectOption[];
  name: string;
}

interface IUpdateClientFormProps extends IRIFormProps<IUpdateClientFormValues> {
  currEntities: IEntity[];
  entities: IEntity[];
  name?: string;
}

export const UpdateClientForm = ({ currEntities, entities, name, onSubmit }: IUpdateClientFormProps) => {
  const entityOptions = useMemo(() => entities.map((e) => ({ label: e.name, value: e._id })), [entities]);

  return (
    <Formik
      initialValues={
        { name, entities: currEntities.map((e) => ({ label: e.name, value: e._id })) } as IUpdateClientFormValues
      }
      onSubmit={(values) => onSubmit({ ...values, name: values.name.trim() })}
    >
      {(props: FormikProps<IUpdateClientFormValues>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-6 mt-10">
          <FormInputWithLabel
            id="name"
            name="name"
            label="Name"
            placeholder="Edit contact name"
            value={props.values.name}
          />
          <FormInputWithLabel
            id="entities"
            name="entities"
            label="Companies"
            placeholder="Edit contact's associated companies"
            value={props.values.entities}
            type="multiselect"
            multiSelectProps={{ options: entityOptions }}
          />
          <FormButton loading={props.isSubmitting} text="Update" />
        </Form>
      )}
    </Formik>
  );
};
