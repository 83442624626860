import { useCallback, useState } from 'react';
import { ITemplateBaseDetails } from '../../../../lib/types';
import { useTemplate, useUpdateTemplate } from '../template.service';
import { logError, sleep } from '../../../../lib/utils';
import { Loader } from '../../../_core/loader.component';
import { ICreateTemplateFormValues } from '../create/create-template-form.component';
import { CreateTemplateNestedContainer } from '../create/create-template-form-nested.container';

export interface IEditTemplateContainerProps {
  close: () => void;
  template: ITemplateBaseDetails;
}

export const EditTemplateContainer = ({ close, template: templateDetails }: IEditTemplateContainerProps) => {
  const { template, loading } = useTemplate(templateDetails._id);
  const { updateTemplate } = useUpdateTemplate(templateDetails.type);

  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const handleUpdate = useCallback(
    async (props: ICreateTemplateFormValues) => {
      if (!template) setSubmitError('Unable to update template');
      else
        try {
          const result = await updateTemplate({ ...props, _id: template?._id }, template);
          if (result.data?.updateTemplate?._id || result.data?.updateGlobalTemplate?._id) {
            setSubmitError(null);
            setSuccess('Updated template successfully!');

            await sleep(500);
            close();
          } else {
            setSuccess(null);
            setSubmitError('Failed to update template');
          }
        } catch (err) {
          const errMsg = 'Failed to update template';
          setSuccess(null);
          setSubmitError(errMsg);
          logError(errMsg, (err as Error).message);
        }
    },
    [close, template, updateTemplate]
  );

  if (loading || !template) return <Loader />;
  return (
    <CreateTemplateNestedContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      data={{ blocks: template.blocks }}
      onSubmit={handleUpdate}
      template={template}
      type={template.type}
      version={template.version}
    />
  );
};
