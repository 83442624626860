import { Loader } from '../../_core/loader.component';
import { IClient, IEntityMember, Identifiable } from '../../../lib/types';
import { Button } from '../../_core/button/button.component';
import { Tabs } from '../../_core/tabs.component';
import { DetailBox } from '../../_core/details/details-box.component';
import { DetailItem } from '../../_core/details/details-item.component';
import { activeStatus } from '../../../utils/wording.helpers';
import { EntityTableNestedContainer } from '../DashboardPage/entity-table/entity-table.nested.container';
import { IAdminProps, IPersonalCompanyProps } from '../../_core/core.types';
import { RIDialog } from '../../_core/dialog/dialog.component';
import { useState } from 'react';
import { UpdateClientContainer } from '../../domains/clients/update/update-client-form.container';

interface IClientProfilePageProps extends IAdminProps, IPersonalCompanyProps {
  deleteClient: (_: Identifiable) => void;
  deleting: boolean;
  client?: IClient;
  members?: IEntityMember[];
  restoreClient: (_: Identifiable) => void;
  restoring: boolean;
}

export const ClientProfilePage: React.FC<IClientProfilePageProps> = ({
  deleteClient,
  deleting,
  client,
  isAdmin,
  isPersonalCompany,
  members,
  restoreClient,
  restoring
}) => {
  const [editing, setEditing] = useState(false);

  if (!client)
    return (
      <div className="mx-auto">
        <Loader />
      </div>
    );

  const clientName = client.name ?? client.user.name;
  return (
    <>
      <h2 className="font-bold text-2xl tracking-tight w-full">Contact Information</h2>
      <DetailBox>
        <DetailItem label="Contact" value={clientName} />
        <DetailItem label="Status" value={activeStatus(!client.deletedAt)} />
        {!isPersonalCompany && (
          <>
            <DetailItem label="Departments" value={client.department.map(({ name }) => name).join(', ')} />
            <DetailItem label="Locations" value={client.location.map(({ name }) => name).join(', ')} />
          </>
        )}
      </DetailBox>
      <DetailBox endAligned>
        {isAdmin && (
          <>
            <Button size="xl" text="Edit Contact" onClick={() => setEditing(true)} />
            <Button
              size="xl"
              text={`${client.deletedAt ? 'Una' : 'A'}rchive Contact`}
              onClick={() => {
                if (client.deletedAt) restoreClient(client);
                else deleteClient(client);
              }}
              loading={client.deletedAt ? restoring : deleting}
            />
          </>
        )}
      </DetailBox>
      <div className="mt-4 flex flex-1 w-full">
        <DetailBox>
          <Tabs
            tabs={[
              {
                title: 'Companies',
                content: !members ? (
                  <Loader />
                ) : (
                  <EntityTableNestedContainer
                    clientModeConfig={{ members }}
                    isAdmin={isAdmin}
                    entities={members.filter(({ deletedAt }) => !deletedAt).map(({ entity }) => entity)}
                  />
                )
              }
            ]}
          />
        </DetailBox>
      </div>
      <RIDialog open={editing} setOpen={setEditing}>
        <UpdateClientContainer clientId={client._id} userName={clientName} members={members ?? []} />
      </RIDialog>
    </>
  );
};
