import { IRequest } from '../../../../lib/types';
import React, { PropsWithChildren } from 'react';
import {
  ITableFilterConfig,
  ITableSearchConfig,
  TableWithActions
} from '../../../_core/table/table-with-actions.component';
import { ITableRowValues } from '../../../_core/table/table.component';
import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';

interface IRequestTableProps extends PropsWithChildren, IAdminProps, IPersonalCompanyProps {
  filterConfig: ITableFilterConfig<IRequest>;
  requests: IRequestTableRowValues[];
  searchConfig: ITableSearchConfig;
  editingDisabled?: boolean;
  draftsMode?: boolean;
}

export interface IRequestTableRowValues extends ITableRowValues {
  request: IRequest;
}

export const RequestTable: React.FC<IRequestTableProps> = ({
  children,
  draftsMode,
  filterConfig,
  requests,
  searchConfig
}) => {
  const headers = ['Last Update'];
  if (!draftsMode) headers.push('Initially Sent');
  headers.push('Contact', 'Company', 'Title', 'Type', 'Deadline');
  if (!draftsMode) headers.push('Completion');
  headers.push('Owner', 'Status', '');

  return (
    <>
      <TableWithActions
        loading={!requests}
        headers={headers}
        values={requests}
        filterConfig={filterConfig}
        searchConfig={searchConfig}
      >
        {children}
      </TableWithActions>
    </>
  );
};
