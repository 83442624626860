import { AcceptInviteForm, IAcceptInviteFormValues } from './accept-invite-form.component';
import { IFormWithStepperProps } from '../../../_core/form/form.types';
import { logError } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { useAcceptInvite } from '../invite.service';
import { FetchInviteErrors } from '../invite.types';
import { InviteType } from '../get-by-code/get-invite-by-code-form.types';
import { useAcceptClientInvite } from '../../client-invite/client-invite.service';

interface IAcceptInviteContainer extends IFormWithStepperProps {
  code?: string;
  type?: InviteType;
}

export const AcceptInviteNestedContainer = ({ code, type, onBack, onError, onSuccess }: IAcceptInviteContainer) => {
  const { acceptInvite } = useAcceptInvite();
  const { acceptClientInvite } = useAcceptClientInvite();

  const handleAccept = async ({ code: selectedCode, type: selectedType }: IAcceptInviteFormValues) => {
    try {
      await resetFormMessages({ setSubmitError: onError, setSuccess: onSuccess });

      if (selectedType === InviteType.CLIENT) await acceptClientInvite({ code: selectedCode });
      else if (selectedType === InviteType.STAFF) await acceptInvite({ code: selectedCode });
      else {
        onError('Invalid invite type.');
        return;
      }

      onSuccess('Successfully accepted invite.');
    } catch (err) {
      const errorMessage = (err as Error).message;

      let msg = 'Failed to accept invite.';
      if (errorMessage === FetchInviteErrors.COMPANY_DELETED) {
        msg = 'Organization has been deleted.';
      } else if (errorMessage === FetchInviteErrors.DOES_NOT_EXIST) {
        msg = 'Invite does not exist.';
      } else if (errorMessage === FetchInviteErrors.EXPIRED) {
        msg = 'Invite expired.';
      }

      logError(msg, errorMessage);
      onError(msg);
    }
  };

  return <AcceptInviteForm code={code} type={type} onSubmit={handleAccept} onBack={onBack} />;
};
