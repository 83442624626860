import { Form, Formik, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { IDepartment } from '../../../../lib/types';
import { ISelectedIDProps } from '../../../../lib/query.types';
import { FormActionsRow } from '../../../_core/form/input/form-actions-row';
import { IMultiSelectOption } from '../../../_core/input/multiselect-input.component';

interface IEditDepartmentsFormFieldProps {
  ids: IMultiSelectOption[];
}

interface IEditDepartmentsFormProps extends IRIFormProps<ISelectedIDProps> {
  departments: IDepartment[];
  selected: IDepartment[];
}

export const EditDepartmentsForm = ({ departments, onBack, onSkip, onSubmit, selected }: IEditDepartmentsFormProps) => {
  return (
    <Formik
      initialValues={
        { ids: selected.map(({ _id: value, name: label }) => ({ label, value })) } as IEditDepartmentsFormFieldProps
      }
      onSubmit={({ ids }) => onSubmit({ ids: ids.map(({ value }) => value) })}
    >
      {(props: FormikProps<IEditDepartmentsFormFieldProps>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-6 mt-10">
          <FormInputWithLabel
            id="ids"
            name="ids"
            label="Departments"
            placeholder="Edit departments"
            value={props.values.ids}
            type="multiselect"
            multiSelectProps={{ options: departments.map((d) => ({ label: d.name, value: d._id })) }}
          />
          <FormActionsRow>
            {!!onBack && <FormButton onPress={onBack} text="Back" type="button" variant="secondary" />}
            {!!onSkip && <FormButton onPress={onSkip} text="Skip" type="button" variant="secondary" />}
            <FormButton loading={props.isSubmitting} text="Update" />
          </FormActionsRow>
        </Form>
      )}
    </Formik>
  );
};
