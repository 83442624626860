import { ITemplateBaseDetails, TEMPLATE_TYPE } from '../../../../lib/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { TemplateTable, ITemplateTableRowValues } from './template-table.component';
import { activeStatus } from '../../../../utils/wording.helpers';
import { TemplateActionButton } from './template-action-button';
import { TemplateTableMultiselectActionButton } from './template-table-multiselect-action-button/template-table-multiselect-action-button';
import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';
import { TableFilterKeys } from '../../../_core/table/table.types';
import { useTableFilterConfig, useTableSearchConfig } from '../../../_core/table/table-utils';
import { ITableFilterOption } from '../../../_core/table/table-with-actions.component';
import { RIDialog } from '../../../_core/dialog/dialog.component';
import { EditRequestContainer } from '../../../domains/request/edit/edit-request.container';
import { PreviewTemplateContainer } from '../../../domains/template/preview/preview-template.container';
import { GlobalIcon } from '../../../_core/icon/global-icon.component';
import { useFetchCurrUser } from '../../../domains/users/user.service';
import { EditTemplateContainer } from '../../../domains/template/edit/edit-template-form.container';
import { BlockTitles, BlockType } from '../../../_editor/_core/editor.const';

interface ITemplateTableNestedContainerProps extends IAdminProps, IPersonalCompanyProps {
  templates?: ITemplateBaseDetails[];
  types: TEMPLATE_TYPE[];
}

export const TemplateTableNestedContainer: React.FC<ITemplateTableNestedContainerProps> = ({
  templates,
  isAdmin,
  isPersonalCompany,
  types
}) => {
  const { isGlobalAdmin } = useFetchCurrUser();

  const [editTemplate, setEditTemplate] = useState<ITemplateBaseDetails | null>(null);
  const [previewTemplate, setPreviewTemplate] = useState<ITemplateBaseDetails | null>(null);
  const [requestTemplate, setRequestTemplate] = useState<ITemplateBaseDetails | null>(null);
  const [selected, setSelected] = useState<boolean[] | null>(null);

  const onSelect = (index: number, isSelected?: boolean) =>
    setSelected((prev) => {
      if (!prev) return null;
      const newSelected = [...prev];
      newSelected[index] = isSelected !== undefined ? isSelected : !newSelected[index];
      return newSelected;
    });

  // TODO: Want to test this with pagination
  useEffect(() => setSelected((s) => templates?.map((_, i) => !!s && i < s.length && s[i]) ?? null), [templates]);

  const getSelectedModels = useCallback(() => {
    if (selected && templates) {
      const selectedTemplates: ITemplateBaseDetails[] = [];
      selected.forEach((s, i) => {
        if (s && i < templates.length) selectedTemplates.push(templates[i]);
      });

      return selectedTemplates;
    }
  }, [selected, templates]);

  const filters = useMemo(() => {
    const newFilters: ITableFilterOption<ITemplateBaseDetails>[] = [];

    if (types.length > 1)
      newFilters.push({
        containerClass: 'w-96',
        title: TableFilterKeys.TYPE,
        placeholder: 'Filter by Type',
        options: Object.values(BlockType),
        labeler: (value) => (value in BlockTitles ? BlockTitles[value as BlockType] : undefined),
        value: [],
        matcher: (curr: ITemplateBaseDetails, filterValue: string[]) => {
          return !filterValue.length || (!!curr.blockType && filterValue.includes(curr.blockType));
        }
      });

    return newFilters;
  }, [types]);

  const [filterConfig, filterFunc] = useTableFilterConfig({ activeStatusFilter: true, filters, ownershipFilter: true });
  const [searchConfig, searchMatches] = useTableSearchConfig({ items: templates, keys: ['description', 'title'] });

  const canCreateRequests = useMemo(() => _.isEqual(types, [TEMPLATE_TYPE.REQUEST]), [types]);
  const showBlockType = useMemo(() => types.length > 1, [types.length]);
  const templateRows = useMemo(() => {
    return searchMatches?.filter(filterFunc)?.map((template, tIndex) => {
      const row: ITemplateTableRowValues = {
        template,
        values: [
          {
            children: (
              <div className="flex">
                {!template.company && <GlobalIcon text="Global Template" />}
                <span>{template.title}</span>
              </div>
            )
          },
          { children: activeStatus(!template.deletedAt) },
          { children: template.type },
          { children: template.description }
        ]
      };

      if (showBlockType)
        row.values.push({ children: template.blockType ? BlockTitles[template.blockType as BlockType] : null });

      row.values.push({
        children: (
          <TemplateActionButton
            index={tIndex}
            template={template}
            isCompanyAdmin={isAdmin}
            isGlobalAdmin={isGlobalAdmin}
            isPersonalCompany={isPersonalCompany}
            onCreateRequest={canCreateRequests ? () => setRequestTemplate(template) : undefined}
            onEdit={() => setEditTemplate(template)}
            onPreview={() => setPreviewTemplate(template)}
          />
        )
      });

      return row;
    });
  }, [searchMatches, filterFunc, showBlockType, isAdmin, isGlobalAdmin, isPersonalCompany, canCreateRequests]);

  if (!selected || !templateRows) return null;

  return (
    <>
      <TemplateTable
        isAdmin={isAdmin}
        isPersonalCompany={isPersonalCompany}
        selected={selected}
        templates={templateRows}
        onSelect={onSelect}
        onSelectAll={(v) =>
          setSelected((s) => {
            if (s) return s.map(() => v);
            return null;
          })
        }
        filterConfig={filterConfig}
        searchConfig={searchConfig}
        showBlockType={showBlockType}
      >
        {(isAdmin || isGlobalAdmin) && (
          <TemplateTableMultiselectActionButton
            getSelectedModels={getSelectedModels}
            selected={selected}
            setSelected={setSelected}
          />
        )}
      </TemplateTable>
      {!!requestTemplate && (
        <RIDialog open={!!requestTemplate} setOpen={(o) => setRequestTemplate((v) => (o ? v : null))}>
          <EditRequestContainer template={requestTemplate} />
        </RIDialog>
      )}
      {!!previewTemplate && (
        <RIDialog open={!!previewTemplate} setOpen={(o) => setPreviewTemplate((v) => (o ? v : null))}>
          <PreviewTemplateContainer templateId={previewTemplate._id} />
        </RIDialog>
      )}
      {!!editTemplate && (
        <RIDialog open={!!editTemplate} setOpen={(o) => setEditTemplate((v) => (o ? v : null))}>
          <EditTemplateContainer close={() => setEditTemplate(null)} template={editTemplate} />
        </RIDialog>
      )}
    </>
  );
};
