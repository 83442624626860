import { Button } from '../../../_core/button/button.component';
import { IRequest, REQUEST_STATUS } from '../../../../lib/types';
import { useMemo, useState } from 'react';
import { RequestTable, IRequestTableRowValues } from './request-table.component';
import { RIDialog } from '../../../_core/dialog/dialog.component';

import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';
import { EditRequestContainer } from '../../../domains/request/edit/edit-request.container';
import { RequestActionButton } from '../../../domains/request/request-action-button';
import { useFetchCurrUser } from '../../../domains/users/user.service';
import { TableFilterKeys } from '../../../_core/table/table.types';
import { useTableFilterConfig, useTableSearchConfig } from '../../../_core/table/table-utils';

export interface IRequestTableContainer extends IAdminProps, IPersonalCompanyProps {
  draftsMode?: boolean;
}

interface IRequestTableNestedContainerProps extends IRequestTableContainer {
  requests?: IRequest[];
}

export const RequestTableNestedContainer: React.FC<IRequestTableNestedContainerProps> = ({
  requests,
  isAdmin,
  draftsMode
}) => {
  const { user } = useFetchCurrUser();

  const [createRequest, setCreateRequest] = useState(false);

  const [searchConfig, searchMatches] = useTableSearchConfig({
    items: requests,
    keys: [
      'name',
      'company',
      'requestType',
      'company.name',
      'assignedTo.name',
      'deadline',
      'sentAt',
      'status',
      'createdBy.name'
    ]
  });
  const [filterConfig, filterFunc] = useTableFilterConfig({
    filters: [
      {
        title: TableFilterKeys.STATUS,
        placeholder: 'Filter by status',
        options: draftsMode
          ? [REQUEST_STATUS.DRAFT, REQUEST_STATUS.ARCHIVED_DRAFT]
          : [REQUEST_STATUS.SENT, REQUEST_STATUS.CLOSED, REQUEST_STATUS.ARCHIVED],
        value: draftsMode ? [REQUEST_STATUS.DRAFT] : [REQUEST_STATUS.SENT],
        matcher: (curr: IRequest, filterValue: string[]) => {
          const isDraft = !curr.status || curr.status === REQUEST_STATUS.DRAFT;
          if (!filterValue.length) return draftsMode ? isDraft : !isDraft;

          if (curr.deletedAt)
            return isDraft
              ? filterValue.includes(REQUEST_STATUS.ARCHIVED_DRAFT)
              : filterValue.includes(REQUEST_STATUS.ARCHIVED);

          return filterValue.includes(curr.status ?? REQUEST_STATUS.DRAFT);
        }
      }
    ]
  });

  const headers = ['Last Update', 'Initially Sent', 'Contact', 'Company', 'Title', 'Type', 'Deadline'];
  if (!draftsMode) headers.push('Completion');
  headers.push('Owner', 'Status', '');
  const requestRows = useMemo(() => {
    if (!searchMatches) return [];
    const sortable = [...searchMatches];
    return sortable
      .filter(filterFunc)
      .sort((a, b) => {
        if (a.deletedAt && b.deletedAt) return a.deletedAt < b.deletedAt ? 1 : -1;
        if (a.deletedAt && !b.deletedAt) return 1;
        if (!a.deletedAt && b.deletedAt) return -1;
        return a.updatedAt < b.updatedAt ? 1 : -1;
      })
      .map((request, i) => {
        const row: IRequestTableRowValues = {
          request,
          values: [{ children: new Date(request.updatedAt).toLocaleString() }]
        };

        if (!draftsMode) row.values.push({ children: request.sentAt ? new Date(request.sentAt).toLocaleString() : '' });

        row.values.push(
          {
            children: request.assignedTo?.name ?? request.assignedTo?.user.name ?? request.assignedTo?.user.email ?? ''
          },
          { children: request.entity?.name ?? '' },
          { children: request.name },
          { children: request.requestType?.type ?? '' },
          { children: request.deadline ? new Date(request.deadline).toLocaleDateString() : '' }
        );

        if (!draftsMode)
          row.values.push({
            children: (
              <div>
                <span>{Math.round(request.completion.percentage * 100)}%</span>
                <span className="ml-2">
                  {request.completion.completedSections}/{request.completion.totalSections}
                </span>
              </div>
            )
          });

        const isDraft = !request.status || request.status === REQUEST_STATUS.DRAFT;
        row.values.push(
          {
            children: (
              <b className={user?._id === request.createdBy._id ? 'text-green-600' : undefined}>
                {request.createdBy.name}
              </b>
            )
          },
          {
            children: request.deletedAt
              ? isDraft
                ? REQUEST_STATUS.ARCHIVED_DRAFT
                : REQUEST_STATUS.ARCHIVED
              : request.status ?? REQUEST_STATUS.DRAFT
          },
          {
            children: (
              <RequestActionButton
                request={request}
                last={searchMatches.length > 3 && i === searchMatches.length - 1}
              />
            )
          }
        );

        return row;
      });
  }, [draftsMode, filterFunc, searchMatches, user?._id]);

  if (!requestRows) return null;

  return (
    <>
      <RequestTable
        filterConfig={filterConfig}
        requests={requestRows}
        searchConfig={searchConfig}
        draftsMode={draftsMode}
      >
        {isAdmin && <Button onClick={() => setCreateRequest(true)} text="Create Request" size="large" />}
      </RequestTable>
      <RIDialog open={createRequest} setOpen={setCreateRequest}>
        <EditRequestContainer />
      </RIDialog>
    </>
  );
};
