import { useCurrCompany, useUpdateCompany } from '../../domains/companies/company.service';
import { FirmProfilePage } from './firm-profile-page.component';
import { Loader } from '../../_core/loader.component';
import { useCompanyInvites } from '../../domains/invite/invite.service';
import { useCompanyMembers, useCurrMember, useRemoveSelf } from '../../domains/member/member.service';
import { useCompanyLocations } from '../../domains/locations/location.service';
import { useCompanyDepartments } from '../../domains/departments/department.service';
import { useCompanyRequestTypes, useSelectedRequestTypes } from '../../domains/request-type/request-type.service';
import { useFetchCurrUser } from '../../domains/users/user.service';
import { useQueryParams } from '../../../services/routing.service';
import { showError } from '../../../lib/utils';
import { ROLE } from '../../../lib/types';

export const FirmProfilePageContainer = () => {
  const { user } = useFetchCurrUser();

  const { departments } = useCompanyDepartments();
  const { invites } = useCompanyInvites();
  const { locations } = useCompanyLocations();
  const { members } = useCompanyMembers();
  const { company, isPersonal } = useCurrCompany();
  const { currMember } = useCurrMember();

  const { companyRequestTypes } = useCompanyRequestTypes();
  const { requestTypes } = useSelectedRequestTypes();

  const { removeSelf, loading: removingSelf } = useRemoveSelf();
  const { updateCompany, loading: updating } = useUpdateCompany();

  const params = useQueryParams();
  const completeOnboarding = params.get('complete-onboarding') === 'true';

  if (!user || !company) return <Loader />;

  return (
    <div className="flex min-h-full flex-1 flex-col items-center">
      <FirmProfilePage
        company={company}
        isPersonalCompany={isPersonal}
        completeOnboarding={completeOnboarding}
        updateCompany={(props) => {
          try {
            updateCompany(props);
          } catch (err) {
            showError('Unable to update organization', err as Error);
          }
        }}
        updating={updating}
        departments={departments ?? []}
        invites={invites}
        locations={locations ?? []}
        members={members}
        inactiveCompanyRequestTypes={companyRequestTypes?.filter(({ deletedAt }) => deletedAt) ?? []}
        selectedRequestTypes={requestTypes ?? []}
        user={user}
        removeSelf={() => {
          try {
            removeSelf();
          } catch (err) {
            showError('Unable to remove self from organization', err as Error);
          }
        }}
        removingSelf={removingSelf}
        memberRole={currMember?.roles.includes(ROLE.COMPANY_ADMIN) ? 'Admin' : 'Team Member'}
      />
    </div>
  );
};
