import React, { useState } from 'react';
import { useFormFieldMeta } from '../form-field-meta';
import { IFormInputProps } from './form-input.component';
import { IMultiSelectInputProps, IMultiSelectOption, MultiSelectInput } from '../../input/multiselect-input.component';

interface IMultiSelectInputHiddenProps {
  error?: React.ReactNode;
}

export const FormMultiSelectInput = ({
  error,
  name,
  ...rest
}: IMultiSelectInputProps & IFormInputProps & IMultiSelectInputHiddenProps) => {
  const {
    value: formValue,
    helpers: { setTouched, setValue: setFormValue }
  } = useFormFieldMeta<IMultiSelectOption[]>(name);

  const [hasBeenTouched, setHasBeenTouched] = useState(false);

  return (
    <MultiSelectInput
      {...rest}
      value={formValue}
      setValue={setFormValue}
      setHasBeenTouched={setHasBeenTouched}
      setTouched={setTouched}
      hasBeenTouched={hasBeenTouched}
    >
      {error}
    </MultiSelectInput>
  );
};
