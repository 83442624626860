import { QuestionType } from '../../question/question.types';
import { inputClasses } from '../editor.const';
import { onReadonlyInputKeydown } from './input.utils';

export const createAnswerInput = ({
  disabled,
  id,
  onAnswerChange,
  options,
  placeholder = '< Enter answer >',
  readOnly,
  type,
  value,
  width = 'w-full'
}: {
  disabled?: boolean;
  id?: string;
  onAnswerChange: (_: Event) => void;
  options?: {
    [QuestionType.NUMBER]?: { min: number; max: number; step?: number };
  };
  placeholder?: string;
  readOnly?: boolean;
  type: QuestionType;
  value?: string;
  width?: 'w-full' | 'w-48';
}) => {
  const answerContainer = document.createElement('div');
  answerContainer.classList.add('answer-container', 'flex', width);

  let answerInput: HTMLInputElement | HTMLTextAreaElement;
  if (type === QuestionType.TEXTAREA) {
    answerInput = document.createElement('textarea');
    answerInput.classList.add('resize');
  } else {
    answerInput = document.createElement('input');
    if (type === QuestionType.CURRENCY) {
      answerInput.type = 'number';
      if (readOnly) {
        answerInput.classList.add('rounded-l-none');

        const prefix = document.createElement('span');
        prefix.classList.add(
          'inline-flex',
          'items-center',
          'px-3',
          'text-sm',
          'text-gray-900',
          'bg-gray-200',
          'border',
          'rounded-s-md',
          'border-gray-300'
        );
        prefix.textContent = '$';
        answerContainer.appendChild(prefix);
      }
    } else if (type === QuestionType.DATE) {
      answerInput.type = 'date';
    } else if (type === QuestionType.EMAIL) {
      answerInput.type = 'email';
    } else if (type === QuestionType.NUMBER) {
      answerInput.type = 'number';

      const numberOptions = options?.[QuestionType.NUMBER];
      if (numberOptions) {
        answerInput.min = numberOptions.min.toString();
        answerInput.max = numberOptions.max.toString();
        if (numberOptions.step) answerInput.step = numberOptions.step.toString();
      }
    } else if (type === QuestionType.PHONE) {
      answerInput.type = 'tel';
    }
  }

  answerInput.classList.add('answer-input', 'w-full', ...inputClasses);
  answerInput.placeholder = placeholder;
  answerInput.value = value ?? '';

  if (disabled) answerInput.disabled = disabled;
  if (id) answerInput.id = id;

  answerInput.oninput = (e) => onAnswerChange(e);
  answerInput.onchange = (e) => onAnswerChange(e);

  if (readOnly) answerInput = onReadonlyInputKeydown(answerInput);

  answerContainer.appendChild(answerInput);

  return answerContainer;
};
