import { useTemplates } from '../../../domains/template/template.service';
import { Loader } from '../../../_core/loader.component';
import { TemplateTableNestedContainer } from './template-table.nested.container';
import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';
import { TEMPLATE_TYPE } from '../../../../lib/types';

interface ITemplateTableContainer extends IAdminProps, IPersonalCompanyProps {
  types: TEMPLATE_TYPE[];
}

export const TemplateTableContainer: React.FC<ITemplateTableContainer> = (props) => {
  const { templates, loading: loadingTemplates } = useTemplates(props.types);

  if (loadingTemplates) return <Loader />;

  return <TemplateTableNestedContainer templates={templates?.filter((t) => t.company || !t.deletedAt)} {...props} />;
};
