import { ILocation, Identifiable } from '../../../../lib/types';
import React, { useState } from 'react';
import { RIDialog } from '../../../_core/dialog/dialog.component';
import { ITableFilterConfig, TableWithActions } from '../../../_core/table/table-with-actions.component';
import { CreateLocationsContainer } from '../../../domains/locations/create/create-locations-form.container';
import { Button } from '../../../_core/button/button.component';
import { activeStatus } from '../../../../utils/wording.helpers';
import { UpdateLocationContainer } from '../../../domains/locations/update/update-location-form.container';
import { ITableRowValues } from '../../../_core/table/table.component';
import { IAdminProps } from '../../../_core/core.types';

export interface ILocationTableProps extends IAdminProps {
  locations: ILocation[];
  filterConfig?: ITableFilterConfig<ILocation>;
}

interface ILocationTableActionProps {
  deleteLocation: (_: Identifiable) => void;
  deleting: boolean;
  restoreLocation: (_: Identifiable) => void;
  restoring: boolean;
}

export const LocationTable: React.FC<ILocationTableProps & ILocationTableActionProps> = ({
  locations,
  deleteLocation,
  deleting,
  isAdmin,
  restoreLocation,
  restoring,
  ...rest
}) => {
  const [createLocations, setCreateLocations] = useState(false);
  const [updateLocation, setUpdateLocation] = useState<ILocation | null>(null);

  const headers = ['Name', 'Status'];
  if (isAdmin) headers.push('');

  return (
    <>
      <TableWithActions
        headers={headers}
        values={
          locations.map(({ _id, name, deletedAt }, i) => {
            const row: ITableRowValues = { values: [{ children: name }, { children: activeStatus(!deletedAt) }] };

            if (isAdmin)
              row.values.push({
                children: (
                  <Button
                    loading={deleting || restoring}
                    size="large"
                    text="ACTION"
                    dropdown={{
                      options: [
                        deletedAt
                          ? { text: 'Reactivate', onClick: () => restoreLocation({ _id }) }
                          : { text: 'Delete', onClick: () => deleteLocation({ _id }) },
                        { text: 'Edit', onClick: () => setUpdateLocation(locations[i]) }
                      ],
                      up: locations.length > 1 && i === locations.length - 1
                    }}
                  />
                )
              });

            return row;
          }) ?? []
        }
        {...rest}
      >
        {isAdmin && <Button onClick={() => setCreateLocations(true)} text="Add Locations" size="large" />}
      </TableWithActions>
      {isAdmin && (
        <>
          <RIDialog open={createLocations} setOpen={setCreateLocations}>
            <CreateLocationsContainer />
          </RIDialog>
          {!!updateLocation && (
            <RIDialog open={!!updateLocation} setOpen={(o) => setUpdateLocation((l) => (o ? l : null))}>
              <UpdateLocationContainer {...updateLocation} />
            </RIDialog>
          )}
        </>
      )}
    </>
  );
};
