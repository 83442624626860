import { uniqueId } from 'lodash';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { DetailBox } from '../details/details-box.component';
import { getInputClasses } from '../input/input-utils';
import { MultiSelectInput } from '../input/multiselect-input.component';
import { Loader } from '../loader.component';
import { ITableProps, Table } from './table.component';

export interface ITableFilterOption<T> {
  containerClass?: string;
  placeholder?: string;
  hidden?: boolean;
  title: string;
  options: string[];
  labeler?: (_: string) => string | undefined;
  value: string[];
  matcher: (_: T, __: string[]) => boolean;
}

export interface ISetSelectedFilterProps {
  title: string;
  value: string[];
}

export interface ITableFilterConfig<T> {
  options: ITableFilterOption<T>[];
  setSelectedFilters: (_: ISetSelectedFilterProps) => void;
}

export interface ITableSearchConfig {
  onChange: (_: string) => void;
  value: string;
}

interface ITableWithActionsProps<T> extends ITableProps, React.PropsWithChildren {
  filterConfig?: ITableFilterConfig<T>;
  loading?: boolean;
  searchConfig?: ITableSearchConfig;
}

export const TableWithActions = <T,>({
  children,
  filterConfig,
  loading,
  searchConfig,
  ...tableProps
}: ITableWithActionsProps<T>) => {
  const { finalContainerClass, inputClasses } = getInputClasses({ type: 'multiselect' });

  const textInputClasses = getInputClasses({ type: 'text' });
  const uid = uniqueId();
  const searchLabel = `table-search-${uid}`;

  return (
    <DetailBox stretch>
      <div className="w-full flex justify-end pt-4 gap-4">
        {!!filterConfig && (
          <div className="w-full flex items-start">
            {!!searchConfig && (
              <div className="flex items-center mt-1 w-96">
                <MagnifyingGlassIcon height={30} width={30} className="mr-2" />
                <input
                  name={searchLabel}
                  className={`${textInputClasses.inputClasses} `}
                  value={searchConfig.value}
                  onChange={({ target }) => searchConfig.onChange(target.value)}
                  placeholder="Enter search text..."
                />
              </div>
            )}
            {filterConfig.options
              ?.filter(({ hidden }) => !hidden)
              .map((f) => (
                <div className="ml-2" key={'table-filter-' + f.title}>
                  <MultiSelectInput
                    placeholder={f.placeholder}
                    inputClasses={inputClasses}
                    containerClass={`${finalContainerClass} ${f.containerClass ?? 'w-48'}`}
                    options={f.options.map((value) => ({ value, label: f.labeler?.(value) }))}
                    setValue={(v) =>
                      filterConfig.setSelectedFilters({ title: f.title, value: v.map(({ value }) => value) })
                    }
                    value={f.value.map((value) => ({ value, label: f.labeler?.(value) }))}
                    tagSize="small"
                  />
                </div>
              ))}
          </div>
        )}
        {children}
      </div>
      {loading ? <Loader /> : <Table {...tableProps} />}
    </DetailBox>
  );
};
