import { IRequestType } from '../../../../lib/types';
import React, { useMemo, useState } from 'react';
import { RIDialog } from '../../../_core/dialog/dialog.component';
import { ITableFilterConfig, TableWithActions } from '../../../_core/table/table-with-actions.component';
import { Button } from '../../../_core/button/button.component';
import { activeStatus } from '../../../../utils/wording.helpers';
import { EditRequestTypesContainer } from '../../../domains/companies/edit-request-types/edit-request-types-form.container';
import { UpdateRequestTypeContainer } from '../../../domains/request-type/update/update-request-type-form.container';
import { ITableRowValues } from '../../../_core/table/table.component';
import { IAdminProps } from '../../../_core/core.types';
import { useTableFilterConfig } from '../../../_core/table/table-utils';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/20/solid';
import { useFetchCurrUser } from '../../../domains/users/user.service';
import { GlobalIcon } from '../../../_core/icon/global-icon.component';
import { useConfirm } from '../../../_core/confirm/confirm.utils';
import { useDeleteGlobalRequestType } from '../../../domains/request-type/request-type.service';

export interface IRequestTypeTableProps extends IAdminProps {
  requestTypes: IRequestType[];
  filterConfig?: ITableFilterConfig<IRequestType>;
}

export const RequestTypeTable: React.FC<IRequestTypeTableProps> = ({ isAdmin, requestTypes, ...rest }) => {
  const { isGlobalAdmin } = useFetchCurrUser();
  const { deleteGlobalRequestType } = useDeleteGlobalRequestType();

  const { ConfirmationDialog, confirm } = useConfirm('Are you sure you want to delete the global request type?');

  const [editRequestTypes, setEditRequestTypes] = useState(false);
  const [updateRequestType, setUpdateRequestType] = useState<IRequestType | null>(null);

  const [filterConfig, filterFunc] = useTableFilterConfig({ activeStatusFilter: true, ownershipFilter: true });
  const filtered = useMemo(() => requestTypes.filter(filterFunc), [filterFunc, requestTypes]);

  const headers = ['Name', 'Status'];
  if (isAdmin) headers.push('');

  return (
    <>
      <TableWithActions
        headers={headers}
        values={
          filtered.map(({ _id, company, type, deletedAt }, i) => {
            const row: ITableRowValues = {
              values: [
                {
                  children: (
                    <div className="flex">
                      {!company && <GlobalIcon text="Global Request Type" />}
                      <span>{type}</span>
                    </div>
                  )
                },
                { children: activeStatus(!deletedAt) }
              ]
            };

            if (isAdmin)
              row.values.push({
                children:
                  company || isGlobalAdmin ? (
                    <div className="flex">
                      <Button
                        icon={<PencilSquareIcon width={20} height={20} />}
                        size="fit"
                        onClick={() => setUpdateRequestType(filtered[i])}
                        tooltip="Edit"
                        variant="secondary"
                      />
                      {!company && isGlobalAdmin && (
                        <Button
                          icon={<TrashIcon width={20} height={20} />}
                          size="fit"
                          tooltip="Delete"
                          onClick={() =>
                            confirm().then((confirmed) => {
                              if (confirmed) deleteGlobalRequestType({ _id });
                            })
                          }
                        />
                      )}
                    </div>
                  ) : null
              });

            return row;
          }) ?? []
        }
        filterConfig={filterConfig}
        {...rest}
      >
        {isAdmin && <Button onClick={() => setEditRequestTypes(true)} text="Edit Request Types" size="large" />}
      </TableWithActions>
      {isAdmin && (
        <>
          <RIDialog open={editRequestTypes} setOpen={setEditRequestTypes}>
            <EditRequestTypesContainer />
          </RIDialog>
          {!!updateRequestType && (
            <RIDialog open={!!updateRequestType} setOpen={(o) => setUpdateRequestType((v) => (o ? v : null))}>
              <UpdateRequestTypeContainer {...updateRequestType} />
            </RIDialog>
          )}
        </>
      )}
      <ConfirmationDialog />
    </>
  );
};
