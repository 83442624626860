import { ApolloCache } from '@apollo/client';
import { IMembersResults } from '../member/member.types';
import { GET_MEMBERS } from '../member/member.queries';
import { GET_COMPANY_INVITES } from '../invite/invite.queries';
import { ICompany, INVITE_STATUS } from '../../../lib/types';
import { ICompanyInviteResult } from '../invite/invite.types';
import { ICurrCompanyRequestTypesResults, ISelectedRequestTypesResults } from '../request-type/request-type.types';
import { ICompanyLocationsResult } from '../locations/location.types';
import { ICompanyDepartmentsResult } from '../departments/department.types';
import { GET_COMPANY_DEPARTMENTS } from '../departments/department.queries';
import { GET_COMPANY_LOCATIONS } from '../locations/location.queries';
import { GET_COMPANY_REQUEST_TYPES, GET_SELECTED_REQUEST_TYPES } from '../request-type/request-type.queries';
import toast from 'react-hot-toast';
import { COMPLETE_ONBOARDING_KEY } from '../../../lib/const';
import { IUserCompaniesResult } from './company.types';
import { IArchivableProps } from '../../../lib/query.types';
import { GET_USER_COMPANIES } from './company.queries';
import { GET_ENTITIES } from '../entities/entity.queries';
import { IEntitiesResults } from '../entities/entity.types';
import { IClientsResults } from '../clients/client.types';
import { GET_CLIENTS } from '../clients/client.queries';
import { GET_CLIENT_INVITES } from '../client-invite/client-invite.queries';
import { IClientInviteQueryParams, IClientInvitesResult } from '../client-invite/client-invite.types';
import { IListRequestAsStaffResults } from '../request/request.types';
import { LIST_REQUESTS_AS_STAFF } from '../request/request.queries';

export const readUserCompaniesCache = (cache: ApolloCache<unknown>) =>
  cache.readQuery<IUserCompaniesResult, IArchivableProps>({
    query: GET_USER_COMPANIES,
    variables: { includeArchived: true }
  }) ?? { companies: [] };

export const wipeCurrCompanyCache = (cache: ApolloCache<unknown>) => {
  toast.dismiss(COMPLETE_ONBOARDING_KEY);

  cache.writeQuery<ICompanyDepartmentsResult, IArchivableProps>({
    query: GET_COMPANY_DEPARTMENTS,
    data: { departments: [] },
    variables: { includeArchived: true }
  });
  cache.writeQuery<ICompanyLocationsResult, IArchivableProps>({
    query: GET_COMPANY_LOCATIONS,
    data: { locations: [] },
    variables: { includeArchived: true }
  });

  cache.writeQuery({
    query: GET_COMPANY_REQUEST_TYPES,
    data: { companyRequestTypes: [] } as ICurrCompanyRequestTypesResults
  });
  cache.writeQuery({ query: GET_SELECTED_REQUEST_TYPES, data: { requestTypes: [] } as ISelectedRequestTypesResults });
  cache.writeQuery({ query: GET_MEMBERS, data: { members: [] } as IMembersResults });
  cache.writeQuery({
    query: GET_COMPANY_INVITES,
    data: { invites: [] } as ICompanyInviteResult,
    variables: { status: INVITE_STATUS.PENDING }
  });

  cache.writeQuery<IEntitiesResults, IArchivableProps>({
    query: GET_ENTITIES,
    data: { entities: [] },
    variables: { includeArchived: true }
  });

  cache.writeQuery<IClientsResults, IArchivableProps>({
    query: GET_CLIENTS,
    data: { clients: [] },
    variables: { includeArchived: true }
  });
  cache.writeQuery<IClientInvitesResult, IClientInviteQueryParams>({
    query: GET_CLIENT_INVITES,
    data: { clientInvites: [] },
    variables: { status: INVITE_STATUS.PENDING }
  });

  cache.writeQuery<IListRequestAsStaffResults>({
    query: LIST_REQUESTS_AS_STAFF,
    data: { listRequestsAsStaff: [] }
  });
};

export const writeUserCompaniesCache = (cache: ApolloCache<unknown>, companies: ICompany[]) => {
  cache.writeQuery<IUserCompaniesResult, IArchivableProps>({
    query: GET_USER_COMPANIES,
    data: { companies },
    variables: { includeArchived: true }
  });
};
