import Header from '@editorjs/header';
import { IBlockConversionData } from '../_core/types';

export class RIHeader extends Header {
  /**
   * Enable Conversion Toolbar. Paragraph can be converted to/from other tools
   */
  static get conversionConfig() {
    return {
      export: ({ text = '' }: { text?: string }) => {
        return JSON.stringify({ label: text } as IBlockConversionData);
      },
      import: (importString: string) => {
        const { label } = JSON.parse(importString) as IBlockConversionData;
        return { text: label };
      }
    };
  }
}
