import { ToolConstructable, ToolSettings } from '@editorjs/editorjs/types/tools';
import { OutputData } from '@editorjs/editorjs';
import { ILabelBlockData } from '../_core/label-block';

export enum SectionType {
  QUESTION_GROUP = 'question-group',
  SECTION = 'section'
}

export interface ISectionData extends ILabelBlockData {
  outputData: OutputData;

  // This data will be rewritten on each first render of an editor, it only needs to be included here so its included on saves when preview toggling. The state is irrelevant in saved data on the backend.
  blockIndex?: number;
  totalBlocks?: number;
  requestSent?: boolean;
}

export interface IEditorToolsConfig {
  [toolName: string]: ToolConstructable | ToolSettings;
}
