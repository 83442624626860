import { NavigateFunction } from 'react-router-dom';
import { IAdminProps, IPersonalCompanyProps } from '../../_core/core.types';
import { DetailBox } from '../../_core/details/details-box.component';
import { Tabs } from '../../_core/tabs.component';
import { ContactsTableContainer } from '../../domains/contacts/contacts-table/contacts-table.container';
import { EntityTableContainer } from './entity-table/entity-table.container';
import { RequestTableContainer } from './request-table/request-table.container';
import { TemplateTableContainer } from './template-table/template-table.container';
import { TEMPLATE_TYPE } from '../../../lib/types';

interface IDashboardPage extends IAdminProps, IPersonalCompanyProps {
  navigate: NavigateFunction;
}

export const DashboardPage: React.FC<IDashboardPage> = (props) => {
  return (
    <div className="flex min-h-full flex-1 flex-col items-center">
      <Tabs
        tabs={[
          {
            title: 'Active Requests',
            content: (
              <DetailBox stretch>
                <RequestTableContainer {...props} />
              </DetailBox>
            )
          },
          {
            title: 'Drafts',
            content: (
              <DetailBox stretch>
                <RequestTableContainer {...props} draftsMode />
              </DetailBox>
            )
          },
          {
            title: 'Contacts',
            content: (
              <DetailBox stretch>
                <Tabs
                  tabs={[
                    { title: 'Companies', content: <EntityTableContainer {...props} /> },
                    { title: 'Contacts', content: <ContactsTableContainer {...props} /> }
                  ]}
                />
              </DetailBox>
            )
          },
          {
            title: 'Templates',
            content: (
              <DetailBox stretch>
                <Tabs
                  tabs={[
                    {
                      title: 'Request Templates',
                      content: <TemplateTableContainer {...props} types={[TEMPLATE_TYPE.REQUEST]} />
                    },
                    {
                      title: 'Form Templates',
                      content: (
                        <TemplateTableContainer {...props} types={[TEMPLATE_TYPE.BLOCK, TEMPLATE_TYPE.SECTION]} />
                      )
                    }
                  ]}
                />
              </DetailBox>
            )
          }
        ]}
      />
    </div>
  );
};
