import { IButtonDropdownOptionProps } from '../../../../_core/button/button-dropdown-option-component';
import { IEntityTableMultiselectActionProps } from './entity-table-multiselect-action-button.types';
import { showError } from '../../../../../lib/utils';
import toast from 'react-hot-toast';
import { useDeleteEntityMember } from '../../../../domains/entity-members/entity-members.service';
import { IEntityTableClientModeProps } from '../entity-table.types';

interface IUseDeleteEntityMemberActionProps extends IEntityTableMultiselectActionProps, IEntityTableClientModeProps {}

export const useDeleteEntityMemberAction = ({
  members,
  getSelectedModels,
  setSelected
}: IUseDeleteEntityMemberActionProps): IButtonDropdownOptionProps => {
  const { deleteEntityMember } = useDeleteEntityMember();

  return {
    onClick: () => {
      const selected = getSelectedModels();
      if (!selected) toast.error('Unable to determine selected');
      else {
        selected.forEach((entity) => {
          const matchingMember = members?.find((m) => m.entity._id === entity._id);
          if (matchingMember)
            deleteEntityMember(matchingMember).catch((err) =>
              showError('Unable to delete member for company ' + entity.name, err)
            );
        });
        setSelected((s) => {
          if (s) return s.map(() => false);
          return null;
        });
      }
    },
    text: 'Delete'
  };
};
