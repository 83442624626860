import { useCallback, useState } from 'react';
import { TEMPLATE_TYPE } from '../../../../lib/types';
import { useCreateTemplate } from '../template.service';
import { ICreateTemplateFormValues } from './create-template-form.component';
import { OutputData } from '@editorjs/editorjs';
import { logError, sleep } from '../../../../lib/utils';
import { CreateTemplateNestedContainer } from './create-template-form-nested.container';

export interface ICreateTemplateContainerProps {
  data: OutputData;
  type: TEMPLATE_TYPE;
  version: string;
}

export const CreateTemplateContainer = ({
  close,
  data,
  type,
  version
}: ICreateTemplateContainerProps & {
  close: () => void;
}) => {
  const { createTemplate } = useCreateTemplate(type);

  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const handleCreate = useCallback(
    async (props: ICreateTemplateFormValues) => {
      try {
        const result = await createTemplate({ ...props, blocks: data.blocks, version });
        if (result.data?.createTemplate?._id || result.data?.createGlobalTemplate?._id) {
          setSubmitError(null);
          setSuccess('Created template successfully!');

          await sleep(500);
          close();
        } else {
          setSuccess(null);
          setSubmitError('Failed to create template');
        }
      } catch (err) {
        const errMsg = 'Failed to create template';
        setSuccess(null);
        setSubmitError(errMsg);
        logError(errMsg, (err as Error).message);
      }
    },
    [close, createTemplate, data, version]
  );

  return (
    <CreateTemplateNestedContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      data={data}
      onSubmit={handleCreate}
      type={type}
      version={version}
    />
  );
};
