import { IButtonDropdownOptionProps } from '../../../../_core/button/button-dropdown-option-component';
import { ITemplateTableMultiselectActionProps } from './template-table-multiselect-action-button.types';
import { showError } from '../../../../../lib/utils';
import toast from 'react-hot-toast';
import { useRestoreTemplate } from '../../../../domains/template/template.service';

export const useRestoreTemplateAction = ({
  getSelectedModels,
  setSelected
}: ITemplateTableMultiselectActionProps): IButtonDropdownOptionProps => {
  const { restoreTemplate } = useRestoreTemplate();

  return {
    onClick: () => {
      const selected = getSelectedModels();
      if (!selected) toast.error('Unable to determine selected');
      else if (!selected.some(({ deletedAt }) => deletedAt)) toast.error('No selected templates can be restored.');
      else {
        selected.forEach((template) =>
          restoreTemplate(template).catch((err: Error) =>
            showError('Unable to restore template ' + template.title, err)
          )
        );
        setSelected((s) => {
          if (s) return s.map(() => false);
          return null;
        });
      }
    },
    text: 'Restore'
  };
};
