import { Outlet } from 'react-router-dom';
import { DefaultPageLayout, IDefaultPageLayoutProps } from './default-page.layout';

export const InsetLayout = ({ children, ...rest }: IDefaultPageLayoutProps) => {
  return (
    <DefaultPageLayout {...rest}>
      <div className="mx-auto max-w-[90%] w-full p-4 flex flex-1 flex-col">
        <Outlet />
        {children}
      </div>
    </DefaultPageLayout>
  );
};
