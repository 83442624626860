import { useCurrCompany, useUserCompanies } from '../../domains/companies/company.service';
import { useFetchCurrUser, useSetActiveCompany } from '../../domains/users/user.service';
import { ProfilePage } from './profile-page.component';

export const ProfilePageContainer = () => {
  const { setActiveCompany, loading: settingActiveCompany } = useSetActiveCompany();

  const { company } = useCurrCompany();
  const { user, loading: loadingUser, isGlobalAdmin } = useFetchCurrUser();
  const { companies, loading: loadingCompanies } = useUserCompanies({});

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center">
      <ProfilePage
        activeCompanyId={company?._id}
        companies={companies}
        loadingCompanies={loadingCompanies}
        user={user}
        loadingUser={loadingUser}
        setActiveCompany={setActiveCompany}
        settingActiveCompany={settingActiveCompany}
        isGlobalAdmin={isGlobalAdmin}
      />
    </div>
  );
};
