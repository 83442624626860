export const ROMAN_NUMERAL_KEYS = [
  '',
  'c',
  'cc',
  'ccc',
  'cd',
  'd',
  'dc',
  'dcc',
  'dccc',
  'cm',
  '',
  'x',
  'xx',
  'xxx',
  'xl',
  'l',
  'lx',
  'lxx',
  'lxxx',
  'xc',
  '',
  'i',
  'ii',
  'iii',
  'iv',
  'v',
  'vi',
  'vii',
  'viii',
  'ix'
];

export const TITLE_BLOCK_TYPES = [
  'branch',
  'checkbox',
  'docProvide',
  'question',
  'questionGroup',
  'radio',
  'repeatSection',
  'section',
  'upload',
  'yesNo'
];
