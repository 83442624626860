import { useCallback, useMemo, useState } from 'react';
import Fuse from 'fuse.js';
import {
  ISetSelectedFilterProps,
  ITableFilterConfig,
  ITableFilterOption,
  ITableSearchConfig
} from './table-with-actions.component';
import _ from 'lodash';
import { ACTIVE_STATUS, Deletable, OptionalCompany } from '../../../lib/types';
import { TableFilterKeys } from './table.types';
import { OrderedOwnershipLevels, Ownership } from '../../../lib/global.types';

export const activeStatusMatcher = (curr: Deletable, filterValue: string[]) => {
  if (!filterValue.length) return true;
  if (curr.deletedAt) return filterValue.includes(ACTIVE_STATUS.INACTIVE);
  return filterValue.includes(ACTIVE_STATUS.ACTIVE);
};

export const ownershipMatcher = (curr: OptionalCompany, filterValue: string[]) => {
  if (!filterValue.length) return true;
  return (
    (filterValue.includes(Ownership.GLOBAL) && !curr.company) ||
    (filterValue.includes(Ownership.ORGANIZATION) && !!curr.company)
  );
};

export const useTableFilterConfig = <T>(props: {
  activeStatusFilter?: boolean;
  filters?: ITableFilterOption<T>[];
  ownershipFilter?: boolean;
}): [ITableFilterConfig<T>, (_: T) => boolean] => {
  const [filters, setFilters] = useState<ITableFilterOption<T>[]>(
    [
      props.activeStatusFilter
        ? {
            title: TableFilterKeys.STATUS,
            placeholder: 'Filter by status',
            options: Object.values(ACTIVE_STATUS),
            value: [ACTIVE_STATUS.ACTIVE],
            matcher: activeStatusMatcher
          }
        : null,
      props.ownershipFilter
        ? {
            title: TableFilterKeys.OWNERSHIP,
            placeholder: 'Filter by ownership',
            options: OrderedOwnershipLevels,
            value: [],
            matcher: ownershipMatcher
          }
        : null,
      ...(props.filters ?? [])
    ].filter((v) => !!v) as ITableFilterOption<T>[]
  );

  const handleSetFilters = ({ title, value }: ISetSelectedFilterProps) => {
    setFilters((prev) => {
      const newFilters = [...prev];
      const filterIndex = newFilters.findIndex((f) => f.title === title);
      if (filterIndex >= 0) {
        newFilters[filterIndex].value = value;
      }

      return newFilters;
    });
  };

  const filterFunc = useCallback((curr: T) => _.every(filters, (f) => f.matcher(curr, f.value)), [filters]);

  return [{ options: filters, setSelectedFilters: handleSetFilters }, filterFunc];
};

export const useTableSearchConfig = <T>({
  items,
  keys
}: {
  items?: T[];
  keys: string[];
}): [ITableSearchConfig, T[] | undefined] => {
  const [searchText, setSearchText] = useState('');
  const onChange = (newSearch: string | null) => setSearchText(newSearch || '');
  const fuse = useMemo(() => new Fuse(items ?? [], { keys, shouldSort: true }), [keys, items]);

  const matches = useMemo(() => {
    if (!searchText) return items;
    return fuse.search(searchText).map((result) => result.item);
  }, [fuse, searchText, items]);

  return [{ onChange, value: searchText }, matches];
};
