import { useQuery } from '@apollo/client';

import { FIND_USER_FOR_REQUEST_TOKEN } from './access-token.queries';
import { IUser } from '../../../lib/types';

export const useTokenUser = (variables: { requestId: string; token: string }) => {
  const { data, ...rest } = useQuery<{ findUserForRequestToken: IUser | null }>(FIND_USER_FOR_REQUEST_TOKEN, {
    fetchPolicy: 'no-cache',
    variables
  });
  return { ...rest, tokenUser: data?.findUserForRequestToken };
};
