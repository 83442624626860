import { PropsWithChildren, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

// Auth0
import { useAuth0 } from '@auth0/auth0-react';

// Apollo
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import toast from 'react-hot-toast';

import { BASE_API_URL } from '../lib/utils';
import { useLogout } from '../components/domains/auth/auth.service';
import { Loader } from '../components/_core/loader.component';
import { tokenVar } from './token.service';

export const RIApolloProvider = ({ children }: PropsWithChildren) => {
  const [client, setClient] = useState<ApolloClient<unknown> | null>(null);
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const { handleLogout } = useLogout();

  useEffect(() => {
    const httpLink = createHttpLink({
      uri: `${BASE_API_URL}/graphql`
    });

    const authLink = setContext(async (_, { headers }) => {
      const options = { headers: { ...headers } };
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();

          if (token) {
            options.headers.Authorization = `Bearer ${token}`;
            tokenVar(options.headers.Authorization);
          }
        } catch (error) {
          await handleLogout(() =>
            toast.error('Re-authentication required, please log in again.', { duration: 5000, id: 'unauthenticated' })
          );
        }
      }

      return options;
    });

    const nextClient = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
      connectToDevTools: import.meta.env.VITE_ENV === 'dev'
    });

    setClient(nextClient);
  }, [handleLogout, isAuthenticated, getAccessTokenSilently]);

  if (isLoading || !client) return <Loader />;

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export const GraphqlProvider = () => {
  return (
    <RIApolloProvider>
      <Outlet />
    </RIApolloProvider>
  );
};
