import _ from 'lodash';
import { useMutation, useQuery } from '@apollo/client';

// Queries
import { DELETE_USER, GET_CURR_USER, REGISTER_USER, SET_ACTIVE_COMPANY, UPDATE_USER } from './user.queries';
import { useCallback } from 'react';
import { IUser } from '../../../lib/types';
import { useLogout } from '../auth/auth.service';
import { ICurrUserResults, IRegisterUserProps, ISetActiveCompanyProps, IUpdateUserParams } from './user.types';
import { logError } from '../../../lib/utils';
import toast from 'react-hot-toast';
import { wipeCurrCompanyCache } from '../companies/company.utils';
import { CURR_COMPANY_REFETCH_QUERIES, USER_COMPANIES_REFETCH_QUERIES } from '../companies/company.const';

export const useFetchCurrUser = () => {
  const { data, ...rest } = useQuery<ICurrUserResults>(GET_CURR_USER, {
    fetchPolicy: 'cache-and-network'
  });

  const user = _.get(data, 'loggedInUser');
  return { ...rest, user, isGlobalAdmin: user?.isAdmin };
};

export const useRegisterUser = ({ authId }: { authId: string }) => {
  const [mutation, rest] = useMutation<{ register: IUser }>(REGISTER_USER, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      // Update logged in user query with newly created user
      if (data?.register) cache.writeQuery({ query: GET_CURR_USER, data: { loggedInUser: data.register } });
    },
    refetchQueries: USER_COMPANIES_REFETCH_QUERIES,
    awaitRefetchQueries: true
  });

  const registerUser = useCallback(
    ({ createPersonalCompany = false }: IRegisterUserProps) =>
      mutation({ variables: { authId, createPersonalCompany } }),
    [authId, mutation]
  );

  return { registerUser, ...rest };
};

export const useDeleteUser = () => {
  const { handleLogout, loading } = useLogout();
  const [mutation, rest] = useMutation(DELETE_USER, {
    context: { serializationKey: 'MUTATION', tracked: true },
    onCompleted: () => handleLogout(),
    onError: (err) => {
      const errMsg = 'Unable to delete user';
      logError(errMsg, (err as Error).message);
      toast.error(errMsg);
    }
  });

  return { deleteUser: mutation, ...rest, loading: rest.loading || loading };
};

export const useSetActiveCompany = () => {
  const [mutation, rest] = useMutation<ISetActiveCompanyProps>(SET_ACTIVE_COMPANY, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      // Update curr user
      if (data?.setActiveCompany) {
        cache.writeQuery({ query: GET_CURR_USER, data: { loggedInUser: data.setActiveCompany } });
        wipeCurrCompanyCache(cache);
      }
    },
    refetchQueries: CURR_COMPANY_REFETCH_QUERIES
  });

  const setActiveCompany = useCallback((companyId: string) => mutation({ variables: { companyId } }), [mutation]);

  return { setActiveCompany, ...rest };
};

export const useUpdateUser = () => {
  const [mutation, rest] = useMutation<{ updateUser: IUser }, IUpdateUserParams>(UPDATE_USER, {
    context: { serializationKey: 'MUTATION', tracked: true },
    update: (cache, { data }) => {
      if (data?.updateUser) cache.writeQuery({ query: GET_CURR_USER, data: { loggedInUser: data.updateUser } });
    }
  });

  const updateUser = useCallback((variables: IUpdateUserParams) => mutation({ variables }), [mutation]);

  return { updateUser, ...rest };
};
