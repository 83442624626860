import { Form, Formik, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { IDepartment } from '../../../../lib/types';
import { ISelectedIDProps } from '../../../../lib/query.types';
import { IMultiSelectOption } from '../../../_core/input/multiselect-input.component';

interface ISelectDepartmentsFormFieldProps {
  ids: IMultiSelectOption[];
}

interface ISelectDepartmentsFormProps extends IRIFormProps<ISelectedIDProps> {
  departments: IDepartment[];
}

export const SelectDepartmentsForm = ({ departments, onSubmit }: ISelectDepartmentsFormProps) => {
  return (
    <Formik
      initialValues={{ ids: [] } as ISelectDepartmentsFormFieldProps}
      onSubmit={({ ids }) => onSubmit({ ids: ids.map(({ value }) => value) })}
    >
      {(props: FormikProps<ISelectDepartmentsFormFieldProps>) => (
        <Form action="#" method="POST" className="sm:max-w-xl mx-auto space-y-6 mt-10">
          <FormInputWithLabel
            id="ids"
            name="ids"
            label="Departments"
            placeholder="Select departments"
            value={props.values.ids}
            type="multiselect"
            multiSelectProps={{ options: departments.map((d) => ({ label: d.name, value: d._id })) }}
          />
          <div className="flex row justify-around">
            <FormButton loading={props.isSubmitting} text="Update" />
          </div>
        </Form>
      )}
    </Formik>
  );
};
