import { Formik, FormikErrors, FormikProps } from 'formik';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRIFormProps } from '../../../_core/form/form.types';
import { IRequest, REQUEST_NOTIFY_OPTION, requestNotifyOptionMultiselectLabels } from '../../../../lib/types';
import { ISendCustomRequestMessageFormParams, SendRequestMessageFormFields } from './send-request-message-form-fields';
import { isValidEmail } from '../../../../utils/validation.helpers';
import { FormActionsRow } from '../../../_core/form/input/form-actions-row';

interface ISendRequestMessageFormProps extends IRIFormProps<ISendCustomRequestMessageFormParams> {
  clientMode: boolean;
  defaultMessage?: string;
  defaultNotify?: (REQUEST_NOTIFY_OPTION.CLIENTS | REQUEST_NOTIFY_OPTION.STAFF)[];
  forceSendAll?: boolean;
  request: IRequest;
  userId: string;
}

export const SendRequestMessageForm = ({
  clientMode,
  defaultMessage = '',
  defaultNotify,
  forceSendAll,
  onBack,
  onSubmit,
  request,
  userId
}: ISendRequestMessageFormProps) => {
  const validateForm = (values: ISendCustomRequestMessageFormParams) => {
    const errors: FormikErrors<ISendCustomRequestMessageFormParams> = {};
    if (values.replyTo && !isValidEmail(values.replyTo)) errors.replyTo = 'Must be valid email';

    // Send custom message validations only
    if (!forceSendAll) {
      if (!values.message) errors.message = 'Required';
      if (values.groupsToggle && !values.notify?.length)
        errors.notify = 'Must specify at least one group or use To option';
      if (!values.groupsToggle && !values.to?.length)
        errors.to = 'Must specify at least one recipient or use notify option';
    }

    return errors;
  };

  return (
    <Formik
      initialValues={
        {
          message: defaultMessage,
          notify: (defaultNotify ?? (clientMode ? [REQUEST_NOTIFY_OPTION.STAFF] : [REQUEST_NOTIFY_OPTION.CLIENTS])).map(
            (value) => ({ label: requestNotifyOptionMultiselectLabels[value], value })
          ),
          to: clientMode
            ? [{ label: request.createdBy.name, secondaryLabel: request.createdBy.email, value: request.createdBy._id }]
            : request.assignedTo
            ? [
                {
                  label: request.assignedTo.name ?? request.assignedTo.user.name,
                  secondaryLabel: request.assignedTo.user.email,
                  value: request.assignedTo._id
                }
              ]
            : [],
          replyTo: '',
          subject: ''
        } as ISendCustomRequestMessageFormParams
      }
      onSubmit={onSubmit}
      validate={validateForm}
      validateOnBlur={false}
    >
      {(props: FormikProps<ISendCustomRequestMessageFormParams>) => (
        <SendRequestMessageFormFields {...props} forceSendAll={forceSendAll} request={request} userId={userId}>
          <FormActionsRow>
            {!!onBack && <FormButton type="button" onPress={onBack} text="Cancel" variant="secondary" />}
            <FormButton loading={props.isSubmitting} text={forceSendAll ? 'Send Request' : 'Send Message'} />
          </FormActionsRow>
        </SendRequestMessageFormFields>
      )}
    </Formik>
  );
};
