import { useState } from 'react';

import { useRegisterUser } from '../user.service';
import { RegisterUserForm } from './register-user-form.component';
import { FormContainer } from '../../../_core/form/form-container.component';
import { IRegisterUserProps } from '../user.types';
import { logError } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';

interface IRegisterUserContainerProps {
  authId: string;
  email: string;
  hideRegisterCompany?: boolean;
  name?: string | null;
  onSuccess: () => void;
}

const RegisterUserContainer = ({
  authId,
  email,
  hideRegisterCompany,
  name,
  onSuccess
}: IRegisterUserContainerProps) => {
  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const { registerUser } = useRegisterUser({ authId });

  const handleRegister = async (props: IRegisterUserProps) => {
    try {
      await resetFormMessages({ setSubmitError, setSuccess });

      await registerUser(props);
      setSubmitError(null);
      setSuccess('Successfully created user');
      if (onSuccess)
        setTimeout(() => {
          onSuccess();
        }, 500);
    } catch (err) {
      const errMsg = 'Unable to create user.';
      logError(errMsg, (err as Error).message);
      setSuccess(null);
      setSubmitError(errMsg);
    }
  };

  return (
    <FormContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title="Register User"
    >
      <RegisterUserForm email={email} hideRegisterCompany={hideRegisterCompany} name={name} onSubmit={handleRegister} />
    </FormContainer>
  );
};

export default RegisterUserContainer;
