import { useState } from 'react';
import { ConfirmModal } from './confirm-modal.component';

export const useConfirm = (
  title: string,
  message?: string
): { ConfirmationDialog: () => JSX.Element; confirm: (_?: string) => Promise<boolean> } => {
  const [subtitle, setSubtitle] = useState(message);
  const [promise, setPromise] = useState<{ resolve: (_: boolean) => void } | null>(null);

  const confirm = (newMsg?: string): Promise<boolean> =>
    new Promise((resolve) => {
      if (newMsg) setSubtitle(newMsg);
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };

  const ConfirmationDialog = () => (
    <ConfirmModal
      open={!!promise}
      title={title}
      subtitle={subtitle}
      onConfirm={handleConfirm}
      onClose={handleCancel}
      skipCloseOnConfirm
    />
  );

  return { ConfirmationDialog, confirm };
};
