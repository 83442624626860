import { useCallback, useMemo } from 'react';
import toast from 'react-hot-toast';
import { Button } from '../../../_core/button/button.component';
import { IButtonDropdownOptionProps } from '../../../_core/button/button-dropdown-option-component';
import { useRemoveClientInvite, useResendClientInvite } from '../../client-invite/client-invite.service';
import { useDeleteClient, useRestoreClient } from '../../clients/client.service';
import { IDepartment, IClientInvite, ILocation, IClient } from '../../../../lib/types';
import { showError } from '../../../../lib/utils';
import { NullableContact } from './contacts-table.types';
import { useDeleteEntityMember, useRestoreEntityMember } from '../../entity-members/entity-members.service';
import { IPersonalCompanyProps } from '../../../_core/core.types';

export interface IContactsActionProps {
  invite?: IClientInvite;
  client?: IClient;
}

export interface IContactsActionButtonProps extends IContactsActionProps, IPersonalCompanyProps {
  departments?: IDepartment[];
  entityId: string;
  locations?: ILocation[];
  setShowEditDepartmentsModal: React.Dispatch<React.SetStateAction<NullableContact>>;
  setShowEditLocationsModal: React.Dispatch<React.SetStateAction<NullableContact>>;
}

export const ContactsActionButton: React.FC<IContactsActionButtonProps> = ({
  departments,
  entityId,
  invite,
  isPersonalCompany,
  locations,
  client,
  setShowEditDepartmentsModal,
  setShowEditLocationsModal
}) => {
  const { deleteClient, loading: removingClient } = useDeleteClient();
  const { deleteEntityMember, loading: deletingEntityMember } = useDeleteEntityMember();
  const { removeClientInvite, loading: removingInvite } = useRemoveClientInvite();
  const { resendClientInvite, loading: resendingInvite } = useResendClientInvite();
  const { restoreClient, loading: restoringClient } = useRestoreClient();
  const { restoreEntityMember, loading: restoringEntityMember } = useRestoreEntityMember({ entityId });

  const editContactsDepartmentsAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      disabled: !departments?.length,
      onClick: () => setShowEditDepartmentsModal(invite ?? client ?? null),
      text: 'Edit Departments'
    }),
    [departments?.length, invite, client, setShowEditDepartmentsModal]
  );

  const editContactsLocationsAction: IButtonDropdownOptionProps = useMemo(
    () => ({
      disabled: !locations?.length,
      onClick: () => setShowEditLocationsModal(invite ?? client ?? null),
      text: 'Edit Locations'
    }),
    [invite, locations?.length, client, setShowEditLocationsModal]
  );

  const onResendInvite = useCallback(async () => {
    if (invite)
      try {
        const result = await resendClientInvite(invite);
        if (!result.data?.resendClientInvite.sentAt) toast.error('Unable to deliver invite');
        else toast.success(`Resent invite`);
      } catch (err) {
        showError('Unable to resend invite', err as Error);
      }
  }, [invite, resendClientInvite]);

  const resendInviteAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onResendInvite, text: 'Resend Invite' }),
    [onResendInvite]
  );

  const onRestoreClient = useCallback(async () => {
    if (client?.entityMemberId && client.entityDeletedAt) {
      try {
        await restoreEntityMember({ _id: client.entityMemberId });
        toast.success(`Restored company member`);
      } catch (err) {
        showError('Unable to restore company member', err as Error);
      }
    } else if (client?.deletedAt) {
      try {
        await restoreClient(client);
        toast.success(`Restored contact`);
      } catch (err) {
        showError('Unable to restore contact', err as Error);
      }
    }
  }, [client, restoreClient, restoreEntityMember]);

  const restoreClientAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onRestoreClient, text: 'Restore' }),
    [onRestoreClient]
  );

  const onDeleteContacts = useCallback(async () => {
    if (invite)
      try {
        await removeClientInvite(invite);
        toast.success(`Deleted invite`);
      } catch (err) {
        showError('Unable to delete invite', err as Error);
      }
    else if (client) {
      if (client.entityMemberId) {
        try {
          await deleteEntityMember({ _id: client.entityMemberId });
          toast.success(`Deleted company member`);
        } catch (err) {
          showError('Unable to delete company member', err as Error);
        }
      } else {
        try {
          await deleteClient(client);
          toast.success(`Deleted contact`);
        } catch (err) {
          showError('Unable to delete contact', err as Error);
        }
      }
    }
  }, [invite, client, removeClientInvite, deleteEntityMember, deleteClient]);

  const deleteContactsAction: IButtonDropdownOptionProps = useMemo(
    () => ({ onClick: onDeleteContacts, text: 'Delete' }),
    [onDeleteContacts]
  );

  const options = useMemo(() => {
    const newOptions: IButtonDropdownOptionProps[] = !isPersonalCompany
      ? [editContactsDepartmentsAction, editContactsLocationsAction]
      : [];

    if (invite) newOptions.push(resendInviteAction);
    if ((client?.entityMemberId && client?.entityDeletedAt) || (!client?.entityMemberId && client?.deletedAt))
      newOptions.push(restoreClientAction);
    if (!client || (client.entityMemberId && !client.entityDeletedAt) || (!client.entityMemberId && !client.deletedAt))
      newOptions.push(deleteContactsAction);
    return newOptions;
  }, [
    isPersonalCompany,
    editContactsDepartmentsAction,
    editContactsLocationsAction,
    invite,
    resendInviteAction,
    client,
    restoreClientAction,
    deleteContactsAction
  ]);

  return (
    <Button
      dropdown={{ options }}
      text="ACTION"
      size="xl"
      loading={
        removingClient ||
        restoringClient ||
        removingInvite ||
        resendingInvite ||
        deletingEntityMember ||
        restoringEntityMember
      }
    />
  );
};
