import { ITemplate, ITemplateBaseDetails } from '../../../../lib/types';
import React, { PropsWithChildren } from 'react';
import {
  ITableFilterConfig,
  ITableSearchConfig,
  TableWithActions
} from '../../../_core/table/table-with-actions.component';
import { ITableRowValues } from '../../../_core/table/table.component';
import { IAdminProps, IPersonalCompanyProps } from '../../../_core/core.types';

interface ITemplateTableProps extends PropsWithChildren, IAdminProps, IPersonalCompanyProps {
  onSelect: (_index: number, _selected?: boolean) => void;
  onSelectAll: (_selected: boolean) => void;
  selected?: boolean[];
  templates: ITemplateTableRowValues[];
  editingDisabled?: boolean;
  filterConfig?: ITableFilterConfig<ITemplate>;
  searchConfig?: ITableSearchConfig;
  showBlockType?: boolean;
}

export interface ITemplateTableRowValues extends ITableRowValues {
  template: ITemplateBaseDetails;
}

export const TemplateTable: React.FC<ITemplateTableProps> = ({ children, showBlockType, templates, ...rest }) => {
  const headers = ['Name', 'Status', 'Type', 'Description'];
  if (showBlockType) headers.push('Block Type');
  headers.push('');

  return (
    <>
      <TableWithActions
        selectable
        loading={!templates}
        headers={headers}
        values={templates}
        onRowClick={rest.onSelect}
        {...rest}
      >
        {children}
      </TableWithActions>
    </>
  );
};
