import { useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import { IUpdateRequestTypeFormValues, UpdateRequestTypeForm } from './update-request-type-form.component';
import { IRequestType } from '../../../../lib/types';
import { logError } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { useUpdateGlobalRequestType, useUpdateRequestType } from '../request-type.service';

export const UpdateRequestTypeContainer = ({ _id, company, type }: IRequestType) => {
  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const { updateRequestType } = useUpdateRequestType();
  const { updateGlobalRequestType } = useUpdateGlobalRequestType();

  const handleUpdate = async (props: IUpdateRequestTypeFormValues) => {
    const isGlobal = !company || props.isGlobal;
    try {
      await resetFormMessages({ setSubmitError, setSuccess });

      if (isGlobal) await updateGlobalRequestType({ ...props, _id });
      else await updateRequestType({ ...props, _id });
      setSubmitError(null);
      setSuccess('Successfully updated request type!');
    } catch (err) {
      const errMsg = isGlobal
        ? 'Unable to update global request type due to other organization dependencies'
        : 'Unable to update request type.';

      logError(errMsg, (err as Error).message);
      setSuccess(null);
      setSubmitError(errMsg);
    }
  };

  return (
    <FormContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title="Update Request Type"
    >
      <UpdateRequestTypeForm isGlobal={!company} type={type} onSubmit={handleUpdate} />
    </FormContainer>
  );
};
