import { useMemo, useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import { useUpdateClient } from '../client.service';
import { UpdateClientForm } from './update-client-form.component';
import { IEntityMember } from '../../../../lib/types';
import { logError } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';
import { useEntities } from '../../entities/entity.service';
import { Loader } from '../../../_core/loader.component';
import { IMultiSelectOption } from '../../../_core/input/multiselect-input.component';

interface IUpdateClientContainerProps {
  clientId: string;
  userName?: string;
  members: IEntityMember[];
}

export const UpdateClientContainer = ({ clientId, userName, members }: IUpdateClientContainerProps) => {
  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const { entities, loading: loadingEntities, called: calledEntities } = useEntities();
  const { updateClient } = useUpdateClient();

  const handleUpdate = async (props: { name: string; entities?: IMultiSelectOption[] }) => {
    try {
      await resetFormMessages({ setSubmitError, setSuccess });

      await updateClient({ ...props, _id: clientId, entities: props.entities?.map(({ value }) => value) });
      setSubmitError(null);
      setSuccess('Successfully updated contact!');
    } catch (err) {
      const errMsg = 'Unable to update contact.';
      logError(errMsg, (err as Error).message);
      setSuccess(null);
      setSubmitError(errMsg);
    }
  };

  const currEntities = useMemo(
    () => members.filter(({ deletedAt }) => !deletedAt).map(({ entity }) => entity),
    [members]
  );

  return (
    <FormContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title="Update Contact"
    >
      {loadingEntities || !calledEntities ? (
        <Loader />
      ) : (
        <UpdateClientForm
          currEntities={currEntities}
          entities={entities ?? []}
          name={userName}
          onSubmit={handleUpdate}
        />
      )}
    </FormContainer>
  );
};
