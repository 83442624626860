import { useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import EditorJs, { OutputBlockData, OutputData } from '@editorjs/editorjs';
import { uniqueId } from 'lodash';
import { ChatBubbleLeftIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { ISectionData, SectionType } from '../../_editor/section/section.types';
import {
  CreateTemplateContainer,
  ICreateTemplateContainerProps
} from '../../domains/template/create/create-template-form.container';
import { IRequest, REQUEST_NOTIFY_OPTION, REQUEST_STATUS, TEMPLATE_TYPE } from '../../../lib/types';
import { ICreateBlockTemplateParams } from '../../_editor/_core/create-template-block';
import { Button } from '../../_core/button/button.component';
import { FormEditor } from '../FormBuilderPage/form-editor.component';
import { RIDialog } from '../../_core/dialog/dialog.component';
import { ROUTE_PATHS } from '../../../_routes';
import { ArrowLeftCircleIcon, Cog6ToothIcon } from '@heroicons/react/20/solid';
import { EditRequestContainer } from '../../domains/request/edit/edit-request.container';
import { RequestActionButton } from '../../domains/request/request-action-button';
import { DownloadRequestAssetsButtonContainer } from '../../domains/request/download-request-assets-button';
import { showError, usePrev } from '../../../lib/utils';
import { SendRequestMessageContainer } from '../../domains/conversation/send-request-message/send-request-message.container';
import { useSendRequest } from '../../domains/request/request.service';
import toast from 'react-hot-toast';

const DEFAULT_SECTION_FORM_DATA: OutputBlockData<string, ISectionData> = {
  id: uniqueId(),
  type: 'section',
  data: {
    completed: false,
    filled: false,
    required: false,
    localTitle: '1)',
    label: 'First Section',
    forceOpen: true,
    outputData: { blocks: [] },
    type: SectionType.SECTION
  }
};

interface IRequestPage {
  canSave: boolean;
  editorRef: React.MutableRefObject<EditorJs | undefined>;
  onSave: () => Promise<OutputData | undefined>;
  readOnly?: boolean;
  request: IRequest;
  saving: boolean;
  token?: string;
}

export const RequestPage = ({ canSave, editorRef, onSave, readOnly, request, saving, token }: IRequestPage) => {
  const navigate = useNavigate();

  const [template, setTemplate] = useState<ICreateTemplateContainerProps | null>(null);
  const [canRefreshData, setCanRefreshData] = useState(false);
  const [editRequest, setEditRequest] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [previewing, setPreviewing] = useState(readOnly);
  const [showConversation, setShowConversation] = useState(false);
  const [preppingSendRequest, setPreppingSendRequest] = useState(false);

  useEffect(() => setPreviewing(readOnly), [readOnly]);

  const { sendRequest, loading: sendingRequest } = useSendRequest({ _id: request._id });

  const isClosed = request.status === REQUEST_STATUS.CLOSED;

  const onTogglePreview = useCallback(
    async (state?: boolean) => {
      setPreviewing((v) => state ?? !v);
      editorRef.current?.readOnly?.toggle(state);
    },
    [editorRef]
  );

  const onClearRequest = useCallback(async () => {
    setCanRefreshData(true);
    onTogglePreview(false);
  }, [onTogglePreview]);

  const prevRequestStatus = usePrev(request.status);

  useEffect(() => {
    if (isReady && editorRef.current)
      if (prevRequestStatus === REQUEST_STATUS.CLOSED && !isClosed)
        // Toggle edit mode when re-opening request
        onTogglePreview(false);
      // Toggle ready only on closed
      else if (prevRequestStatus !== REQUEST_STATUS.CLOSED && isClosed && !previewing) onTogglePreview(true);
  }, [editorRef, isClosed, isReady, onTogglePreview, prevRequestStatus, previewing, request.status]);

  const onCreateRequestTemplate = () => {
    onSave()
      .then((data) => {
        if (data) setTemplate({ data, type: TEMPLATE_TYPE.REQUEST, version: data.version ?? EditorJs.version });
        else toast.error('Failed to save request and create template');
      })
      .catch((err) => showError('Failed to save request', err));
  };

  const onCreateBlockTemplate = ({ data, type = TEMPLATE_TYPE.SECTION }: ICreateBlockTemplateParams) => {
    setTemplate({ data: { blocks: [{ data: data.data, type: data.type }] }, type, version: EditorJs.version });
  };

  const editorData: OutputData = {
    time: request.time,
    version: request.version,
    blocks: request.blocks ?? [DEFAULT_SECTION_FORM_DATA]
  };

  const handleSave = () => {
    return onSave().then((result) => {
      setHasChanged(false);
      return result;
    });
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center">
      <div id="request-actions" className="w-full bg-white">
        <div className="max-w-[90%] w-full flex items-center justify-between p-2 mx-auto">
          <div className="flex flex-1 gap-4 items-center">
            {!readOnly && (
              <>
                <Button
                  className="hover:opacity-50 hover:ring hover:ring-inset hover:ring-black"
                  icon={<ArrowLeftCircleIcon height={28} width={28} color="black" />}
                  onClick={() => navigate(ROUTE_PATHS.DASHBOARD)}
                  size="fit"
                  variant="custom"
                />
                <Button
                  className="hover:opacity-50 hover:ring hover:ring-inset hover:ring-black"
                  icon={<Cog6ToothIcon height={28} width={28} color="black" />}
                  onClick={() => setEditRequest(true)}
                  size="fit"
                  variant="custom"
                />
              </>
            )}
            {readOnly ? (
              <>
                {request.status === REQUEST_STATUS.CLOSED && (
                  <h3 className="bg-secondary py-1 px-2 text-white">Closed</h3>
                )}
              </>
            ) : (
              <>
                {!!request.entity && (
                  <h3 className="bg-tertiary py-1 px-2 text-white">
                    <b>Company:</b> {request.entity.name}
                  </h3>
                )}
                <h3 className="bg-primary py-1 px-2 text-white">
                  <b>TO:</b>{' '}
                  {request.assignedTo?.name ??
                    request.assignedTo?.user.name ??
                    request.assignedTo?.user.email ??
                    'None'}
                </h3>
              </>
            )}
          </div>
          <div className="flex gap-4 relative">
            {request.status === REQUEST_STATUS.SENT && !!token && (
              <Button
                hideEndMargin
                icon={<ChatBubbleLeftIcon width={24} height={24} className="mr-2" />}
                variant="secondary"
                onClick={() => setShowConversation(true)}
                text="Respond"
              />
            )}
            {!readOnly && (
              <>
                <RequestActionButton
                  loading={sendingRequest}
                  hideViewAction
                  request={request}
                  togglePreview={onTogglePreview}
                  onClearRequest={onClearRequest}
                  onCreateTemplate={() => onCreateRequestTemplate()}
                  onSave={handleSave}
                  onSendRequest={() => {
                    setPreppingSendRequest(true);
                    setShowConversation(true);
                  }}
                  onResendRequest={() => setShowConversation(true)}
                  hasChanged
                />
                {!isClosed && (
                  <Button
                    onClick={() => onTogglePreview()}
                    size="medium"
                    text={previewing ? 'Edit' : 'Preview'}
                    variant="secondary"
                  />
                )}
              </>
            )}
            {canSave && (
              <Button
                loading={saving}
                onClick={onSave}
                size="medium"
                text="Save"
                icon={
                  hasChanged ? (
                    <ExclamationCircleIcon width={16} height={16} color="gold" style={{ marginRight: 2 }} />
                  ) : undefined
                }
              />
            )}
            <DownloadRequestAssetsButtonContainer
              request={request}
              onSave={onSave}
              requestToken={token}
              forceLabelsOnDocProvide={!!token || previewing}
            />
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-[90%] w-full p-4 flex flex-1 flex-col m-6 bg-white rounded-2xl">
        <Outlet />
        <div className="shadow-customShort mx-auto mb-1 p-2 w-full relative flex items-center justify-center rounded-t">
          {!readOnly && (
            <h3
              className={`${
                request.deletedAt || !request.status || request.status === REQUEST_STATUS.DRAFT
                  ? 'text-red-500'
                  : 'text-secondary'
              } absolute left-2 text-sm`}
            >
              {request.deletedAt ? 'Archived' : request.status ?? 'DRAFT'}
            </h3>
          )}
          <h2 className="text-center text-xl text-secondary text-bold">{request.name}</h2>
        </div>
        {/* TODO: Fix overflow-x here, so whole editor can scroll */}
        <div className="shadow-custom mx-auto w-full rounded-b">
          {/* TODO: Does each bit of data need to be memoized to prevent the Editor re-renders? */}
          {/* TODO: Should force re-render on company switch */}
          <FormEditor
            canRefreshData={canRefreshData}
            setCanRefreshData={setCanRefreshData}
            data={editorData}
            disabled={isClosed || !!request.deletedAt}
            editorblock={'editorjs'}
            editorRef={editorRef}
            onCreateTemplate={onCreateBlockTemplate}
            onReady={() => {
              setIsReady(true);
            }}
            onSave={handleSave}
            openFirstSection={!!token}
            previewing={previewing}
            quickCreateTitle="add section"
            readOnly={readOnly || isClosed || false}
            requestId={request._id}
            requestSent={request.status === REQUEST_STATUS.SENT}
            type="base"
            token={token}
          />
        </div>
      </div>
      {!!template && (
        <RIDialog open={!!template} setOpen={(o) => setTemplate((v) => (o ? v : null))}>
          <CreateTemplateContainer close={() => setTemplate(null)} {...template} />
        </RIDialog>
      )}
      {!!editRequest && (
        <RIDialog open={!!editRequest} setOpen={setEditRequest}>
          <EditRequestContainer close={() => setEditRequest(false)} request={request} />
        </RIDialog>
      )}
      {!!showConversation && (
        <RIDialog open={!!showConversation} setOpen={setShowConversation}>
          <SendRequestMessageContainer
            close={() => {
              setPreppingSendRequest(false);
              setShowConversation(false);
            }}
            request={request}
            token={token}
            forceSendAll={preppingSendRequest}
            onSubmit={
              preppingSendRequest
                ? ({ message, notify = [REQUEST_NOTIFY_OPTION.ALL], replyTo }) =>
                    sendRequest({ customMessage: message, replyTo, notify })
                : undefined
            }
          />
        </RIDialog>
      )}
    </div>
  );
};
