import { useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import { SelectRolesForm } from './select-roles-form.component';
import { ISelectedRolesProps } from '../../../../lib/query.types';
import { useUpdateInvites } from '../../invite/invite.service';
import { useUpdateMembers } from '../../member/member.service';
import { IStaffTableMultiselectActionProps } from '../../../_pages/FirmProfilePage/staff-table/staff-table-multiselect-action-button/staff-table-multiselect-action-button.types';
import { logError } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';

export const SelectRolesContainer = ({
  currUserId,
  getSelectedModels,
  setSelected
}: IStaffTableMultiselectActionProps) => {
  const { updateInvites } = useUpdateInvites();
  const { updateMembers } = useUpdateMembers();

  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const handleSubmit = async (props: ISelectedRolesProps) => {
    try {
      await resetFormMessages({ setSubmitError, setSuccess });

      const selected = getSelectedModels();
      if (!selected) {
        setSuccess(null);
        setSubmitError('Unable to determine selected');
      } else {
        const selectedInvites = selected.selectedInvites;
        const selectedMembers = selected.selectedMembers.filter(({ user }) => user._id !== currUserId);
        if (!selectedInvites.length && !selectedMembers.length) {
          setSuccess(null);
          setSubmitError('No invites or non-current user members selected');
        } else {
          await Promise.all([
            updateInvites({ ids: selectedInvites.map(({ _id }) => _id), roles: props.roles }),
            updateMembers({ ids: selectedMembers.map(({ _id }) => _id), roles: props.roles })
          ]);
          setSubmitError(null);
          setSuccess('Successfully selected roles');
          setSelected((s) => (s ? s.map(() => false) : null));
        }
      }
    } catch (err) {
      const errMsg = 'Unable to assign selected roles.';
      logError(errMsg, (err as Error).message);
      setSuccess(null);
      setSubmitError(errMsg);
    }
  };

  return (
    <FormContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title="Select Roles"
    >
      <SelectRolesForm onSubmit={handleSubmit} />
    </FormContainer>
  );
};
