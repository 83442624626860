import { Form, Formik, FormikProps } from 'formik';
import { FormInputWithLabel } from '../../../_core/form/input/form-labelled-input.component';
import { FormButton } from '../../../_core/form/button/form-button.component';
import { IRegisterUserProps } from '../user.types';

interface IRegisterUserFormValues {
  createPersonalCompany: boolean;
}

interface IRIFormProps {
  email: string;
  hideRegisterCompany?: boolean;
  name?: string | null;
  onSubmit: (props: IRegisterUserProps) => Promise<void>;
}

export const RegisterUserForm = ({ email, hideRegisterCompany, name, onSubmit }: IRIFormProps) => {
  return (
    <Formik
      initialValues={{ createPersonalCompany: false }}
      onSubmit={(values) => onSubmit({ createPersonalCompany: values.createPersonalCompany })}
    >
      {(props: FormikProps<IRegisterUserFormValues>) => (
        <Form className="space-y-6" action="#" method="POST">
          <FormInputWithLabel
            id="email"
            type="email"
            name="email"
            disabled={true}
            label="Email address"
            value={email}
          />
          <FormInputWithLabel id="name" name="name" label="Name" disabled={true} value={name} />
          {!hideRegisterCompany && (
            <FormInputWithLabel
              id="createPersonalCompany"
              name="createPersonalCompany"
              label="Create Personal Organization?"
              type="checkbox"
              value={props.values.createPersonalCompany}
            />
          )}
          <FormButton loading={props.isSubmitting} text="Register" />
        </Form>
      )}
    </Formik>
  );
};
