import { ICompany, PLAN_NAME } from '../../../lib/types';
import { IMultiSelectOption } from '../../_core/input/multiselect-input.component';

export enum RegisterCompanyOption {
  JOIN_WITH_CODE,
  REGISTER_FIRM,
  REGISTER_PERSONAL
}

export interface ICurrCompanyResult {
  company: ICompany;
}

export interface IEditRequestTypesProps {
  requestTypes: IMultiSelectOption[];
}

export interface IRegisterCompanyProps {
  name?: string;
  planName: PLAN_NAME;
}

export interface IUpdateCompanyProps {
  active?: boolean;
  completedOnboarding?: boolean;
}

export interface IUserCompaniesResult {
  companies: ICompany[];
}
