import { BlockToolConstructorOptions } from '@editorjs/editorjs/types/tools';
import { Radio, RadioData } from '../radio';
import { localTitleBlockImport } from '../_core/utils/block.utils';
import { radioBlockExport } from '../radio/radio.utils';
import { BlockTitles, BlockType } from '../_core/editor.const';

export class YesNo extends Radio {
  constructor(props: BlockToolConstructorOptions<RadioData>) {
    super(props);

    this.data.options = this.data.options ?? ['Yes', 'No'];
  }

  static get toolbox() {
    return {
      title: BlockTitles[BlockType.YesNo],
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M3 7.5 7.5 3m0 0L12 7.5M7.5 3v13.5m13.5 0L16.5 21m0 0L12 16.5m4.5 4.5V7.5" /></svg>'
    };
  }

  static get conversionConfig() {
    return { export: radioBlockExport, import: localTitleBlockImport };
  }
}
