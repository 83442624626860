import { useDeleteClient } from '../../../clients/client.service';
import { IButtonDropdownOptionProps } from '../../../../_core/button/button-dropdown-option-component';
import { IContactsTableMultiselectActionProps } from './contacts-table-multiselect-action-button.types';
import { showError } from '../../../../../lib/utils';
import toast from 'react-hot-toast';
import { useRemoveClientInvite } from '../../../client-invite/client-invite.service';

export const useDeleteContactsAction = ({
  getSelectedModels,
  setSelected
}: IContactsTableMultiselectActionProps): IButtonDropdownOptionProps => {
  const { removeClientInvite } = useRemoveClientInvite();
  const { deleteClient } = useDeleteClient();

  return {
    onClick: () => {
      const selected = getSelectedModels();
      if (!selected) toast.error('Unable to determine selected');
      else {
        const selectedInvites = selected.selectedInvites;
        const selectedClients = selected.selectedClients;
        if (!selectedInvites.length && !selectedClients.length) toast.error('No invites or contacts selected');
        else {
          selectedInvites.forEach((invite) =>
            removeClientInvite(invite).catch((err) => showError('Unable to delete invite ' + invite.email, err))
          );
          selectedClients.forEach((client) =>
            deleteClient(client).catch((err) =>
              showError('Unable to delete contact ' + (client.name ?? client.user.name), err)
            )
          );
          setSelected((s) => {
            if (s) return s.map(() => false);
            return null;
          });
        }
      }
    },
    text: 'Delete'
  };
};
