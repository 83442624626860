import { useCallback } from 'react';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { Button } from '../../_core/button/button.component';
import { useDownloadAsset } from './asset.service';
import { EditorApolloProvider } from '../../_editor/_core/editor-apollo-provider';
import { UploadItemData } from '../../_editor/upload/upload.types';
import { IRequestTokenParams } from '../../../lib/types';
import { getDownloadFileName } from './download-utils';

interface IDownloadAssetButtonProps extends UploadItemData, IRequestTokenParams {
  getLatestFileLabel: (_: string) => string | null;
  forceDownloadWithLabel?: boolean;
}

const DownloadAssetButton = ({
  forceDownloadWithLabel,
  getLatestFileLabel,
  requestToken,
  ...itemData
}: IDownloadAssetButtonProps) => {
  const { downloadAsset } = useDownloadAsset(itemData, requestToken);

  const handleDownload = useCallback(
    ({ useOriginalFileName }: { useOriginalFileName?: boolean }) => {
      const fileName = getDownloadFileName({ useOriginalFileName, getLatestFileLabel, itemData });

      downloadAsset(fileName);
    },
    [downloadAsset, getLatestFileLabel, itemData]
  );

  return (
    <Button
      fit="right"
      hideEndMargin
      icon={<ArrowDownTrayIcon height={16} width={16} />}
      variant="secondary"
      dropdown={
        forceDownloadWithLabel
          ? undefined
          : {
              options: [
                { text: 'With original filenames', onClick: () => handleDownload({ useOriginalFileName: true }) },
                { text: 'With labels as file names', onClick: () => handleDownload({}) }
              ],
              hideIcon: true,
              hidePadding: true,
              ignoreRelativeContainer: true,
              maxWidth: 'max-w-52'
            }
      }
      onClick={forceDownloadWithLabel ? () => handleDownload({}) : undefined}
    />
  );
};

export const DownloadAssetButtonContainer = (props: IDownloadAssetButtonProps) => {
  return (
    <EditorApolloProvider>
      <DownloadAssetButton {...props} />
    </EditorApolloProvider>
  );
};
