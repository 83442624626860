import { useState } from 'react';
import { DialogOptionsModal, IDialogOptionsButtonProps } from './dialog-options-modal.component';

export const useDialogOptions = <T,>({
  title,
  message,
  buttons
}: {
  title: string;
  message?: string;
  buttons: IDialogOptionsButtonProps<T>[];
}): { Dialog: () => JSX.Element; open: () => Promise<T> } => {
  const [promise, setPromise] = useState<{ resolve: (_: T) => void } | null>(null);

  const open = (): Promise<T> =>
    new Promise((resolve) => {
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleClick = (result: T) => {
    promise?.resolve(result);
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const Dialog = () => (
    <DialogOptionsModal
      open={!!promise}
      title={title}
      subtitle={message}
      onClose={handleCancel}
      onClick={handleClick}
      buttons={buttons}
    />
  );

  return { Dialog, open };
};
