import { OutputData } from '@editorjs/editorjs';
import { FormContainer, IFormMessageProps } from '../../../_core/form/form-container.component';
import { ITemplateBaseDetails, TEMPLATE_TYPE } from '../../../../lib/types';
import { CreateTemplateForm, ICreateTemplateFormValues } from './create-template-form.component';

export interface ICreateTemplateContainerProps extends IFormMessageProps {
  data: OutputData;
  onSubmit: (_: ICreateTemplateFormValues) => Promise<void>;
  template?: ITemplateBaseDetails;
  type: TEMPLATE_TYPE;
  version: string;
}

export const CreateTemplateNestedContainer = ({ data, onSubmit, template, ...rest }: ICreateTemplateContainerProps) => {
  return (
    <FormContainer {...rest} title={`${template ? 'Update' : 'Create'} Template`}>
      <CreateTemplateForm data={data} onSubmit={onSubmit} template={template} />
    </FormContainer>
  );
};
