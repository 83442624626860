import { gql } from '@apollo/client';
import { USER_FRAGMENT } from '../users/user.queries';
import { COMPANY_FRAGMENT } from '../companies/company.queries';

export const ACCESS_TOKEN_FRAGMENT = gql`
  fragment AccessTokenDetails on AccessToken {
    _id
    scope
    scopeId
    token
    deletedAt
    expiryDate
    user {
      ...UserDetails
    }
    company {
      ...CompanyDetails
    }
  }

  ${COMPANY_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const FIND_USER_FOR_REQUEST_TOKEN = gql`
  query FindUserForRequestToken($token: String!, $requestId: ID!) {
    findUserForRequestToken(token: $token, requestId: $requestId) {
      ...UserDetails
    }
  }

  ${USER_FRAGMENT}
`;
