import { Disclosure } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { useMemo, useRef } from 'react';
import { useOnClickOutside } from '../../utils/click';
import { useLogout } from '../../../domains/auth/auth.service';
import { Button } from '../../button/button.component';
import { ICompany, IUser } from '../../../../lib/types';
import { ScreenReaderText, SectionTitle } from '../../typography';

interface INavigationItem {
  name: string;
  href: string;
}

type INavigationSectionItem = string | INavigationItem;

const firmNavigation = [{ name: 'Profile', href: '/profile/organization' }];
const userNavigation = [{ name: 'Profile', href: '/profile' }];

interface PageHeaderMenuContentProps {
  close: () => void;
  company?: ICompany | null;
  user?: IUser;
}

export const PageHeaderMenuContent = ({ close, company, user }: PageHeaderMenuContentProps) => {
  const { handleLogout, loading: loggingOut } = useLogout();

  const navigate = useNavigate();

  const ref = useRef(null);
  useOnClickOutside(ref, close);

  const navigation = useMemo(() => {
    const newNav: INavigationSectionItem[] = [{ name: 'Dashboard', href: '/dashboard' }];

    if (user) newNav.push(user.name ?? 'User', ...userNavigation);
    if (company) newNav.push(company.name ?? 'Organization', ...firmNavigation);

    return newNav;
  }, [company, user]);

  return (
    <div className="fixed bg-opacity-40 bg-secondary w-full h-full z-10 top-0 left-0">
      <nav
        className="bg-secondary fixed top-0 right-0 bottom-0 flex flex-col w-5/6 max-w-sm py-6 px-6 bg-white overflow-y-auto"
        ref={ref}
      >
        <div className="flex w-full justify-end mb-8">
          <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md  p-2 text-gray-400 hover:bg-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
            <span className="absolute -inset-0.5" />
            <ScreenReaderText>Close main menu</ScreenReaderText>
            <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
          </Disclosure.Button>
        </div>
        <div>
          <ul>
            {navigation.map((item, i) => {
              // Render nav section title
              if (typeof item === 'string')
                return (
                  <li key={'nav-section-' + item + i} className="flex justify-center mt-8 mb-2">
                    <SectionTitle>{item}</SectionTitle>
                  </li>
                );

              // Render nav item
              const isActive = window.location.pathname === item.href;
              return (
                <li key={'nav-item-' + item.name + i} className="mb-1">
                  <button
                    className={`block p-4 text-sm font-semibold text-black-300 hover:bg-blue-100 hover:text-gray-400 rounded w-full text-left text-center ${
                      isActive ? 'bg-secondary text-white' : ''
                    }`}
                    onClick={() => {
                      navigate(item.href);
                      setTimeout(() => {
                        close();
                      }, 200);
                    }}
                  >
                    {item.name}
                  </button>
                </li>
              );
            })}
            <li>
              <div className="p-8 sm:mx-auto sm:w-full sm:max-w-sm gap-3 flex flex-col">
                <Button text="Sign Out" onClick={() => handleLogout()} loading={loggingOut} />
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};
