import { IButtonDropdownOptionProps } from '../../../../_core/button/button-dropdown-option-component';
import { ITemplateTableMultiselectActionProps } from './template-table-multiselect-action-button.types';
import { showError } from '../../../../../lib/utils';
import toast from 'react-hot-toast';
import { useDeleteTemplate } from '../../../../domains/template/template.service';

export const useDeleteTemplateAction = ({
  getSelectedModels,
  setSelected
}: ITemplateTableMultiselectActionProps): IButtonDropdownOptionProps => {
  const { deleteTemplate } = useDeleteTemplate();

  return {
    onClick: () => {
      const selected = getSelectedModels();
      if (!selected) toast.error('Unable to determine selected');
      else {
        selected.forEach((template) =>
          deleteTemplate(template).catch((err: Error) => showError('Unable to delete template ' + template.title, err))
        );
        setSelected((s) => {
          if (s) return s.map(() => false);
          return null;
        });
      }
    },
    text: 'Delete'
  };
};
