import { useState } from 'react';
import { FormContainer } from '../../../_core/form/form-container.component';
import { SelectDepartmentsForm } from './select-departments-form.component';
import { IDepartment } from '../../../../lib/types';
import { ISelectedIDProps } from '../../../../lib/query.types';
import { useUpdateInvites } from '../../invite/invite.service';
import { useUpdateMembers } from '../../member/member.service';
import { IStaffTableMultiselectActionProps } from '../../../_pages/FirmProfilePage/staff-table/staff-table-multiselect-action-button/staff-table-multiselect-action-button.types';
import { logError } from '../../../../lib/utils';
import { resetFormMessages } from '../../../_core/form/form.helpers';

interface ISelectDepartmentsContainerProps extends IStaffTableMultiselectActionProps {
  departments: IDepartment[];
}

export const SelectDepartmentsContainer = ({
  departments,
  getSelectedModels,
  setSelected
}: ISelectDepartmentsContainerProps) => {
  const { updateInvites } = useUpdateInvites();
  const { updateMembers } = useUpdateMembers();

  const [success, setSuccess] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const handleSubmit = async (props: ISelectedIDProps) => {
    try {
      await resetFormMessages({ setSubmitError, setSuccess });

      const selected = getSelectedModels();
      if (!selected) setSubmitError('Unable to determine selected');
      else {
        await Promise.all([
          updateInvites({ ids: selected.selectedInvites.map(({ _id }) => _id), department: props.ids }),
          updateMembers({ ids: selected.selectedMembers.map(({ _id }) => _id), department: props.ids })
        ]);
        setSubmitError(null);
        setSuccess('Successfully selected departments');
        setSelected((s) => (s ? s.map(() => false) : null));
      }
    } catch (err) {
      const errMsg = 'Unable to assign selected departments.';
      logError(errMsg, (err as Error).message);
      setSuccess(null);
      setSubmitError(errMsg);
    }
  };

  return (
    <FormContainer
      error={submitError}
      setError={setSubmitError}
      success={success}
      setSuccess={setSuccess}
      title="Select Departments"
    >
      <SelectDepartmentsForm departments={departments} onSubmit={handleSubmit} />
    </FormContainer>
  );
};
